
export const paperpresent={
   1: {
      "id": 1,
      "title": "Bug Bounty",
      "image": "card10.webp",
      "link":"/debug"
    },
   2: {
      "id": 2,
      "title": "Ninja Coding ",
      "image": "card4.webp",
      "link":"/ninja"
    },
   3: {
      "id": 3,
      "title": "Cryptic Haunt",
      "image": "card2.webp",
       "link":"/cryptic"
      
    }
}

export const ninja={
   1: {
      "id": 1,
      "title": "Sql Bingo",
      "image": "card7.webp",
      "link":"/sqlbingo"
    },
   2: {
      "id": 2,
      "title": "Paper Presentation",
      "image": "card8.webp",
      "link":"/paperpresent"
    },
   3: {
      "id": 3,
      "title": "Cryptic Haunt",
      "image": "card2.webp",
       "link":"/cryptic"
      
    }
}

export const cryptic={
   1: {
      "id": 1,
      "title": "Bug Bounty",
      "image": "card10.webp",
      "link":"/debug"
    },
   2: {
      "id": 2,
      "title": "Css Art",
      "image": "card6.webp",
      "link":"/cssart"
    },
   3: {
      "id": 3,
      "title": "Rebrand Rumble",
      "image": "card3.webp",
       "link":"/rebrand"
      
    }
}

export const battle={
   1: {
      "id": 1,
      "title": "Ninja Coding",
      "image": "card4.webp",
      "link":"/ninja"
    },
   2: {
      "id": 2,
      "title": "Tech Escape",
      "image": "card1.webp",
      "link":"/techescape"
    },
   3: {
      "id": 3,
      "title": "Connexion",
      "image": "card9.webp",
       "link":"/connexion"
      
    }
}

export const sql={
   1: {
      "id": 2,
      "title": "Css Art",
      "image": "card6.webp",
      "link":"/cssart"
    },
    2: {
      "id": 2,
      "title": "Paper Presentation",
      "image": "card8.webp",
      "link":"/paperpresent"
    },
   3: {
      "id": 3,
      "title":"Battle of bids",
      "image": "card5.webp",
       "link":"/battle"
      
    }
}

export const css={
   1: {
      "id": 1,
      "title": "Sql Bingo",
      "image": "card7.webp",
      "link":"/sqlbingo"
    },
    2: {
      "id": 2,
      "title": "Tech Escape",
      "image": "card1.webp",
      "link":"/techescape"
    },
    3: {
      "id": 3,
      "title": "Rebrand Rumble",
      "image": "card3.webp",
       "link":"/rebrand"
      
    }
}

export const rebrand={
   1: {
      "id": 3,
      "title": "Connexion",
      "image": "card9.webp",
       "link":"/connexion"
      
    },
    2: {
      "id": 1,
      "title": "Bug Bounty",
      "image": "card10.webp",
      "link":"/debug"
    },
    3: {
      "id": 2,
      "title": "Ninja Coding ",
      "image": "card4.webp",
      "link":"/ninja"
    }
    
}

export const tech={
   1: {
      "id": 3,
      "title": "Cryptic Haunt",
      "image": "card2.webp",
       "link":"/cryptic"
      
    },
    2: {
      "id": 1,
      "title": "Sql Bingo",
      "image": "card7.webp",
      "link":"/sqlbingo"
    },
   3: {
      "id": 2,
      "title": "Paper Presentation",
      "image": "card8.webp",
      "link":"/paperpresent"
    }
    
}

export const connexion={
   1: {
      "id": 3,
      "title":"Battle of bids",
      "image": "card5.webp",
       "link":"/battle"
      
    },
    2: {
      "id": 2,
      "title": "Css Art",
      "image": "card6.webp",
      "link":"/cssart"
    },
   3: {
      "id": 3,
      "title": "Rebrand Rumble",
      "image": "card3.webp",
       "link":"/rebrand" 
    }
}

export const debug={
   1: {
      "id": 3,
      "title":"Battle of bids",
      "image": "card5.webp",
       "link":"/battle"
      
    },
    2: {
      "id": 2,
      "title": "Tech Escape",
      "image": "card1.webp",
      "link":"/techescape"
    },
   3: {
      "id": 3,
      "title": "Connexion",
      "image": "card9.webp",
       "link":"/connexion"
      
    }
}