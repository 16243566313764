export const sqlbingo = {
  hero: {
    checker: 0,
    title1: "SQL",
    title2: "BINGO",
    eventId: 8,
  },
  description: {
    title: 'Description',
    content: 'Put your SQL skills to the test in this exciting and fast-paced event! Participants will receive a 5x5 grid containing 25 SQL query questions. The objective is to form 5 straight lines, either horizontally, vertically, or diagonally, by solving consecutive questions—just like in the classic game of Bingo. However, speed is key, as the event is based on the time taken to complete the challenge! Are you ready to race against the clock and prove your SQL expertise? Join us for the SQL Bingo Challenge!'
  },
  rulesSchedule: {
    title: 'Rules and Schedule',
    points: [
      '	Solve SQL queries to form 5 straight lines (horizontal, vertical, or diagonal) on the Bingo grid.',
      '	Each participant will receive a 5x5 grid containing numbers from 1 to 25.',
      ' 25 questions will be given to the participants. The numbers in the grid correspond to the serial numbers of the questions given..',
      '	To finish, you must solve 5 consecutive questions in a straight line, either horizontally, vertically, or diagonally',
      '	Participants are ranked based on completion time. The quickest players will be ranked higher.',
      
    ],
    
    time:' 11 AM to 12.30 PM',
    venue:'To Be Displayed'
  },
  contact:{
    name1:'Srivenkatachalapathy M',
    contact1:'6380232362',
    mail1:'msrivenkatachalapathy@gmail.com',
    name2:'Kaviya B ',
    contact2:'807237292',
    mail2:'kaviyab912@gmail.com',
  },
  prize:"3000"

};
export const Battle_cont = {
  hero: {
    checker: 2,
    title1: "BATTLE",
    title2: "OF BIDS",
    eventId: 3,
  },
  description: {
    title: 'Description',
    content: 'Experience the thrill of an IPL-style player auction right at your college symposium! Teams will compete in a high-energy auction to bid for cricket players, just like the real IPL. With a limited budget in hand, each team must strategize carefully to build their dream squad by bidding for the best cricket stars available. Will you go all out for top players, or will you craft a balanced team with smart picks? The competition will be fierce, and every bid could make or break your team’s chances. This is your opportunity to step into the shoes of an IPL franchise owner and create a championship-winning lineup. Let the excitement unfold as the gavel drops.'
  },
  rulesSchedule: {
    title: 'Rules and Schedule',
    points: [
      'The team consists of 3 members',
      '	Every team will be given an equal amount of currency (purse) at the start of the auction, ensuring a level playing field for bidding.',
      ' Each team must acquire exactly 5 players during the auction with the following roles:	2 Batsmen , 	2 Bowlers,	1 All-rounder',
      ' Teams cannot exceed or deviate from these role requirements.',
      ' All players available for auction will have predefined base prices (minimum bidding amounts) and a set number of points based on their past performance, skill level, and overall potential.',
      '	Teams will bid for players by offering an amount higher than the base price, within the limits of their available purse.',
      ' Bidding continues until no other team is willing to raise the offer. The player is then sold to the highest bidder.',
      '	The winning team must have successfully built a squad of exactly 5 players: 2 Batsmen, 2 Bowlers, and 1 All-rounder.',
      '	The team that acquires all required players and has the highest total points across their players will be declared the winner.',
      ' Teams must balance their purse usage while bidding to ensure they do not overspend on certain players, leaving enough currency to complete the team and maximize the total points.'

    ],
    time:' 3.15 PM to 4.30 PM',
    venue:'To Be Displayed'
  },
  contact:{
    name1:'Deep R Shah ',
    contact1:'8610673845',
    mail1:'deeprshah12345@gmail.com',
    name2:'Yamunaa R K ',
    contact2:'9042747715',
    mail2:'rkyamunaa@gmail.com',
  },
  prize:"Trophy"

};
export const Connexion_cont = {
  hero: {
    checker: 2,
    title1: "CONNEXION",
    title2: "",
    eventId: 2,
  },
  description: {
    title: 'Description',
    content: 'ConneXion is an exciting, fast-paced event where teams of two will compete against each other in a battle of wits and strategy. Each team will be given a slot to solve as many challenges as possible within the time frame. The team that completes the most challenges in their slot will be declared the winner of the round. As the competition progresses, the rounds will become increasingly difficult, introducing complex tasks to test the participants’ skills. To keep the energy high and the atmosphere fun, some rounds will feature "dare" challenges that must be completed to proceed. These dares will add an extra layer of excitement and entertainment to the event. With each round, the tasks become tougher, but so does the thrill of victory. The teams that prove their prowess will move forward, battling their way to the final showdown.'
  },
  rulesSchedule: {
    title: 'Rules and Schedule',
    points: [
      ' Each team must have exactly 2 players.',
      ' Each team will be given a 5-minute slot to solve as many challenges as possible. The team that completes the most challenges during their slot will be declared the winner of the round.',
      ' The competition consists of multiple rounds. As the rounds progress, the difficulty level of the challenges will increase. Teams will face more complex tasks that will test their knowledge, strategy, and quick thinking.',
      ' Some rounds will feature "dare" challenges, which teams must complete to proceed to the next challenge. These dares are designed to add an element of excitement and unpredictability to the event.',
      ' Teams that successfully complete the highest number of challenges in each round will advance to the next stage of the competition. The final rounds will be the toughest, where the best teams will battle for the ultimate victory.',
      '	Any form of cheating, including using external resources like search engines or AI assistants, will result in immediate disqualification.',
      '	Teams must work together to solve challenges. Communication and quick decision-making will be key to their success.',
      '	The team that completes the most challenges or solves the toughest tasks in the final round will be declared the winner of conneXion.'


    ],
    time:' 12.30 PM to 1.30 PM',
    venue:'To Be Displayed'
  },
  contact:{
    name1:'Arjun Prakash AS ',
    contact1:'9894913713',
    mail1:'arjunprakashas03@gmail.com',
    name2:'Bowsul Nilofar N ',
    contact2:'9342572428',
    mail2:'bowsulnilofar2004@gmail.com',

  },
  prize:"Trophy"

};
export const Cryptic_cont = {
  hero: {
    checker: 2,
    title1: "CRYPTIC HAUNT",
    title2: "WITH OUIJA",
    eventId: 4,
  },
  description: {
    title: 'Description',
    content: 'In this thrilling and immersive symposium event, teams of three must communicate with the ghost of a cryptographer using an Ouija board to solve the mystery of his brutal murder and his wife’s kidnapping. The ghost provides encrypted clues about his five murderers, and each correct decryption "eliminates" a killer and reveal clues to the wife’s location.The catch? A wrong decryption eliminates a player, who can only be revived if the remaining team members correctly decode the next clue. Teams receive a video with encrypted details about the murderers, which they must decode and report to the coordinators. Time is of the essence—teams must work under pressure to solve all the clues, find the wife, and finish the challenge in the shortest time to win.This event combines cryptography, strategy, and teamwork, offering an exciting test of problem-solving skills! '
  },
  rulesSchedule: {
    title: 'Rules and Schedule',
    points: [
      '  Each team must have exactly 3 players.',
      '  For every incorrect answer, one team member (called a detective) will be eliminated. The organizing team will decide the victim based on a specific criterion, which will be revealed during the event.',
      '  Any form of cheating is strictly forbidden. Teams caught using external resources (like Chrome or ChatGPT) will be disqualified.',
      '  Active team members can discuss among themselves, but they are not allowed to consult with any teammates who have been "killed" (eliminated due to a wrong answer).',
      '  The event has a strict time limit of 1 hour 15 min (75 minutes).',
      '	 The event will concentrate on coding-decoding and encryption-decryption strategies'
    ],
    time:' 3.15 PM to 4.30 PM',
    venue:'To Be Displayed'
  },
  contact:{
    name1:'Vijay Muruga A ',
    contact1:'6379364312',
    mail1:'vijaymuruga200201@gmail.com',
    name2:'Lokanya S ',
    contact2:'9344055575',
    mail2:'lokanyasekar77@gmail.com',
  },
  prize:"Trophy"

};
export const Cssart_cont = {
  hero: {
    checker: 0,
    title1: "CSS",
    title2: "ART",
    eventId: 5,
  },
  description: {
    title: 'Description',
    content: 'An exciting and creative test where participants will be pushed to the limits of their design and coding abilities! In this event, you will be given a stunning design, and your task is to recreate it using nothing but pure CSS. This challenge is not only about replicating a design but also about showcasing your artistic skills and technical mastery of CSS. You’ll need to think outside the box, using everything from gradients, borders, and transforms to shadows, pseudo-elements, and animations to craft a visually accurate representation of the design you are given. Will you rise to the challenge and bring the design to life with just your CSS skills? It’s time to find out! get creative, and show off your CSS artistry! '
  },
  rulesSchedule: {
    title: 'Rules and Schedule',
    points: [
      ' 	This is a single-person event, no team entries are allowed, Output is displayed only three times',
      '  	Keep the HTML as minimal as possible.',
      '  	No extra div tags should be used unless absolutely necessary.',
      ' 	Participants are only allowed to use External CSS. ',
      ' 	Animations are allowed using CSS, but no external libraries.',
      ' 	We offer two distinct levels:beginner and  intermediate.',
      ' 	The artwork should be purely css based and the output to be displayed should satisfy our constraints.',
      '   Those who complete rounds 1 and 2 within the given time will be declared the winners.',

    ],
    time:' 2.45 PM to 3.30 PM',
    venue:'To Be Displayed'
  },
  contact: {
    name1: 'Dhakshana R ',
    contact1: '8754993113',
    mail1: 'sundarichandran1975@gmail.com',
    name2: 'Naveen Kumar C',
    contact2: '9360432889',
    mail2: 'naveen33303@gmail.com',
  },
  prize:"3000"

};
export const Ninja_cont = {
  hero: {
    checker: 0,
    title1: "NINJA",
    title2: "CODING",
    eventId: 9,
  },
  description: {
    title: 'Description',
    content: 'This event is designed to test not only your coding abilities but your mental agility and focus in the face of constant surprises and mind-bending distractions. It’s not just about writing code or solving problems; it’s about keeping your cool and staying sharp when things get tricky. In this high-pressure environment, you’ll be faced with unpredictable challenges that will push your concentration and adaptability to the limit. The key here is not just technical expertise but how well you can perform under pressure. It’s a true test of your resilience, speed, and ability to handle the unexpected. Step up, stay sharp, and show that you can code through any challenge with precision and calm under pressure! '
  },
  rulesSchedule: {
    title: 'Rules and Schedule',
    points: [
      'Participation is strictly individual',
      ' Participants are requested to use any of the following languages (Python, Java, C, C++).',
      ' Participants must stay within the designated coding area for the duration of the event.',
      '  Scoring is based on accuracy, speed, time complexity and performance under pressure',
      ' Cheating or disruptive behaviour leads to immediate disqualification',
      ' Participants are requested to bring their own laptops, if not available kindly contact the event coordinators',
      'During the event, participants will experience noise and minor task-related disturbances.'
    ],
    time:' 2 PM to 3.30 PM',
    venue:'To Be Displayed'
  },
  contact: {
    name1: 'Arockiya Belcy VS',
    contact1: '9487891749',
    mail1: 'arockiyabelcyvs@gmail.com',
    name2: 'Ummal Fajeela N',
    contact2: '8610587749',
    mail2: 'ummalfajeela25@gmail.com',
  },
  prize:"3500"

};
export const PaperPresent_cont = {
  hero: {
    checker: 1,
    title1: "PAPER",
    title2: "PRESENTATION",
    eventId: 1,
  },
  description: {
    title: 'Description',
    content: 'Paper Presentation Event is an excellent platform for participants to showcase their knowledge, creativity, and problem-solving abilities in the field of Computer Science. Through a combination of a well-structured PowerPoint presentation and a compelling speech, participants will present their research, ideas, and innovative solutions on topics that revolve around key areas of computer science.  The goal is to demonstrate not only their technical understanding but also their ability to communicate complex concepts effectively to a panel of judges and an audience. Whether focusing on theoretical research or proposing real-world solutions, this event challenges participants to think critically and provide fresh insights into the evolving landscape of computer science.'
  },
  rulesSchedule: {
    title: 'Rules and Schedule',
    points: [
      ' 	Each team can have a maximum of two members.',
      '  	The presentation should consist of 10 slides only.',
      ' 	The Font Size should be 14, and the font should be Sans-Serif',
      '   Do not use dark themes for slides.',
      '  	Each team will have a maximum of 5 minutes to present their slides.',
      '   The abstract must be one page long and precise.',
      '	  Participants can pick a topic of their choice related to computer science',
      '	  A specific time slot will be assigned to each team for their presentation.',
      '   If a team misses their time slot, they will not be allowed to present later.',
      '	  Participants must submit their title and abstract on or before October 17 thorugh the provided email (cseasso.acgcet@gmail.com).',
      '   If the number of participants exceeds 30, they will be shortlisted based on their submitted title and abstract.'
    ],
    time:' 11 AM',
    venue:'To Be Displayed'
  },
  contact: {
    name1: 'Prasanna Sridhar M ',
    contact1: '9751796504',
    mail1: 'sridharprasanna868@gmail.com',
    name2: 'Nithyasri S ',
    contact2: '9597792897',
    mail2: 'umanithyacm@gmail.com',
  },
  prize:"3000"

};
export const Rebrand_cont = {
  hero:{
    checker:0,
    title1:"REBRAND",
    title2:"RUMBLE",
    eventId:6
  },
  description: {
    title: 'Description',
    content: 'A fun and creative event where participants put both their recognition skills and design talents to the test! In this event, participants will be shown a series of logos that could represent anything. The first challenge is to correctly identify the logo.  Once you’ve successfully recognized the logo, the real fun begins! You’ll then be tasked with redesigning it, bringing your own artistic flair and creativity to transform the original into something fresh and innovative. The participant who creates the most impressive redesign will be crowned the winner. So, are you ready to identify, create, and compete? Step up and let your creativity flow in the event!'
  },
  rulesSchedule: {
    title: 'Rules and Schedule',
    points: [
      '  	Participants may use any graphic design software (e.g., Canva, Photoshop, Illustrator).',
      '  	No online logo generators or automated design tools are permitted.',
      ' 	Each participant will receive a different logo.',
      '  	Identification is limited to two attempts: one initial guess and one follow-up guess if needed.',
      ' 	Redesigns must be original interpretations; copying or using pre-made templates is prohibited',
      '	  This is a single-person event; no team entries are allowed.',
      '	  All submissions must be completed and submitted by the specified deadline. Late entries will be automatically rejected.',
      '	  Based on Originality and innovative aspects of the redesign and Technical skill and overall quality of the design, Winners will be selected.'

    ],
    time:' 11 AM to 12.30 PM',
    venue:'To Be Displayed'
  },
  contact: {
    name1: 'Magimmuthu ',
    contact1: '8610712393',
    mail1: 'magim290104@gmail.com',
    name2: 'Meenachi ',
    contact2: '7448372681',
    mail2: 'meenachikaruppaiah@gmail.com',
  },
  prize:"3000"


};
export const Techescape_cont = {
  hero:{
    checker:0,
    title1:"TECH ESCAPE",
    title2:"ROOM",
    eventId:7
  },
  description: {
    title: 'Description',
    content: 'Are you ready to outsmart the room and escape solo? The Tech Escape Room is an electrifying individual challenge where you\'re locked in a virtual vault of puzzles and tech mysteries. To break free, you\'ll need to crack codes, solve riddles, and conquer tech challenges—all on your own! It\'s a race against the clock, and only the sharpest mind can escape.Think you\'re up for the challenge? Get ready to test your skills and see if you have what it takes to be the quickest escape artist! Expect brain-twisting fun and an adrenaline rush like never before!'
  },
  rulesSchedule: {
    title: 'Rules and Schedule',
    points: [
      ' Individual member',
      ' Anyone who attempts to cheat by using ChatGPT, chrome or other resources will be eliminated.',
      ' The event is centered around solving puzzles and logical problems.',
      ' The quickest participant to solve all puzzles and escape wins the challenge.'
    ],
    time:' 3.15 PM to 4.30 PM',
    venue:'To Be Displayed'
  },
  contact:{
    name1:'Mugesh M',
    contact1:'9360980648',
    mail1:'mugeshmurugan185@gmail.com',
    name2:'Abirami K',
    contact2:'8778822870',
    mail2:'abiramikanthasamy@gmail.com',
  },
  prize:"Trophy"


};
export const Debug_cont = {
  hero:{
    checker:0,
    title1:"BUG BOUNTY",
    title2:"BATTLE",
    eventId:10
  },
  description: {
    title: 'Description',
    content: 'Are you ready to put your debugging skills to the test? Join us for the Challenge, where participants will receive error codes and dive into the task of identifying and fixing bugs. Each round will feature new and increasingly complex errors across different coding scenarios. Speed and accuracy will be key as participants work through the challenges. Whether you\'re a beginner or an experienced coder, this is a great opportunity to enhance your skills and learn new debugging techniques. Prizes await those who rise to the top!'
  },
  rulesSchedule: {
    title: 'Rules and Schedule',
    points: [
      ' Participation is solely on an individual basis',
      ' Participants are asked to solve as many problems as possible within the given stipulated time.',
      ' Each participant will have a 1-hour time slot to attempt the challenges.',
      ' There will be 9 problems, divided into 3 categories of difficulty - Easy, Medium, and Hard.',
      ' Problems can be solved using any of the following languages - C, Python, or Java.',
      ' Points will be awarded based on the number and difficulty of problems solved. ',
      ' Any form of cheating, including using external resources such as search engines or AI assistants, will result in immediate disqualification. ',
      ' The individual who completes the most challenges will be declared the winner. ',
      ' If multiple participants solve the same number of problems, solving the most difficult tasks will be the deciding factor. ',
    ],
    time:' 12.30 PM to 1.30 PM',
    venue:'To Be Displayed'
  },
  contact:{
    name1:'Thasneem Banu S',
    contact1:'8056845861',
    mail1:'thasneem068@gmail.com',
    name2:'Muthukrishnan M',
    contact2:'9363130574',
    mail2:'muthukrishnan121317@gmail.com',
  },
  prize:"3000"

};








