// Teamscard.js
import React, { useState } from 'react';
import './Teamscard.css';
import { Link } from 'react-router-dom';

function Teamscard({ teamImage, Teamcardbottom, role, name, fontcolor, linkedin, showLinkedIn }) {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  const handleCardClick = () => {
    setIsModalOpen(true); // Open the modal when card is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div>
      <div className="team-card" onClick={handleCardClick}>
        <img id="Team-card-img" src={teamImage} alt="Team Card" />
        <div id="card-bottom">
          <img id="Team-card-bottom" src={Teamcardbottom} alt="Team Card Bottom" />
          <p id="role" style={{ color: fontcolor }}>{role}</p>
          <p id="name" style={{ color: fontcolor }}>{name}</p>
        </div>
      </div>

      {/* Modal implementation */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={handleCloseModal}>X</button>
            <img src={teamImage} alt={name} className="modal-image" />
            <p id="role-in-model">{role}</p>
            <h2 id="name-in-model">{name}</h2>

            {/* Conditionally render LinkedIn button */}
            {showLinkedIn && (
              <div className="linked_button">
                <Link id="team-card-link" to={linkedin}>LinkedIn</Link>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Teamscard;