import React ,{useEffect}from "react";
import "./Description.css";
import { Ninja_cont } from "../../Constants/description";
import cup from "../../Assets/cup.webp";
import { HeroSection } from "../../Container";
import { ninja } from "../../Constants/additionalcard";
import { Additionalcard } from "../../Widget";

const Ninja= () => {
  useEffect(() => {
    document.body.scrollTop=0;
    document.documentElement.scrollTop=0;
    }, [window.location]);
  return (
    <>
    <HeroSection
    checker={Ninja_cont.hero.checker}
    title1={Ninja_cont.hero.title1}
    title2={Ninja_cont.hero.title2}
    eventId={Ninja_cont.hero.eventId}/>
    <div id="description" className="presentation-container">
      <div className="description_whole">
        <h1 className="events_desc_heading">{Ninja_cont.description.title}</h1>
        <div className="description_section">
          <div className="glassmorphism-box">
            <div className="main_description_content">
              <p>{Ninja_cont.description.content}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="rules_section">
        <h1 id="rules">{Ninja_cont.rulesSchedule.title}</h1>
        <ul>
  {Ninja_cont.rulesSchedule.points.map((point,index) => (
    <li >
      <span className="rule-index">Rule {index +1} : </span> {point}
    </li>
  ))}
</ul>

        <div className="des_time_venue">
          <span className="rule-index">
        TIME :</span>{Ninja_cont.rulesSchedule.time}
          
          <div className="des_venue">
          <span className="rule-index">
            VENUE : </span>
          {Ninja_cont.rulesSchedule.venue}
          </div>
        </div>
      </div>

      <div id="prize"className="prize_section">
        <div className="prize_content">
          <div className="prize_image">
            <img src={cup} alt="Prize" />
          </div>

          <div className="prize_text">
            <div className="prize_head">
            <h2 className="prize_head">PRIZE</h2>
            </div>
            <div className="des_amt">
            <p className="des_amt">{Ninja_cont.prize}</p>
            </div>
            <div className="des_indian_rupee">
              <h className="des_indian_rupee">
              {Ninja_cont.prize==="Trophy"?"":"Indian Rupees"}
              </h>
            </div>
          </div>
        </div>
      </div>
      <div className="description_contact">
        <div className="footer_heading">
          <h1 id="contact">CONTACT</h1>
        </div>
        <div className="footer_content">
          <div className="contact_item">
            <p>{Ninja_cont.contact.name1}</p>
            <a href={`tel:${Ninja_cont.contact.contact1}`} className="contact_link">
            {Ninja_cont.contact.contact1}
            </a>
            <br />
            <a href={`mailto:${Ninja_cont.contact.mail1}`} className="contact_link">
            {Ninja_cont.contact.mail1}
            </a>
          </div>

          <div className="contact_item">
            <p>{Ninja_cont.contact.name2}</p>
            <a href={`tel:${Ninja_cont.contact.contact2}`} className="contact_link">
            {Ninja_cont.contact.contact2}
            </a>
            <br />
            <a href={`mailto:${Ninja_cont.contact.mail2}`} className="contact_link">
            {Ninja_cont.contact.mail2}
            </a>
          </div>
        </div>
      </div>
    </div>
    <Additionalcard data={ninja}/>
    </>
  );
};

export default Ninja;
