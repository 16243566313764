import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Event_card.css';

function Event_card({ image, characterImage, eventLink, eventTitle, bottomImage }) {
  const navigate = useNavigate();

  const handleKnowMoreClick = () => {
    navigate(eventLink); 
  };

  return (
    <div className="event_card_box">
      <div className="card">
        <div className="wrapper">
          <img src={image} className="cover-image" alt={eventTitle} />
        </div>
        <img src={characterImage} className="character" alt={`${eventTitle} Character`} />
        <div className="bottom-image-container">
          <img src={bottomImage} className="bottom-image" alt={`${eventTitle} Bottom Image`} />
          <div className="event_initial-text">{eventTitle}</div>
        </div>
        <div className="event_hover-text">
          <button className="event_hover_button" onClick={handleKnowMoreClick}>Know More</button>
        </div>
      </div>
    </div>
  );
}

export default Event_card;
