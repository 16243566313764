import React, { useState, useEffect } from "react";
import "./Navbar.css";

import arrow_right from "../../Assets/pictures/nav-arrow-right.webp";
import arrow_left from "../../Assets/pictures/nav-arrow-left.webp";

import menu1 from "../../Assets/pictures/menu_home.webp"
import menu2 from "../../Assets/pictures/menu_about.webp"
import menu3 from "../../Assets/pictures/menu_login.webp"
import menu4 from "../../Assets/pictures/menu_events.webp"
import menu5 from "../../Assets/pictures/menu_contact.webp"
import menu6 from "../../Assets/pictures/menu_team.webp"
import { TextEffect } from "../../Widget";

const Navbar = () => {
  const [windowpath, setWindowpath] = useState("/");
  const [navopen, setNavopen] = useState(true);
  const [firstflag, setFirstflag] = useState(0);
  const [navAnimation, setNavAnimation] = useState(false)

  const navbarOpener = () => {
    if (firstflag === 0) {
      setFirstflag(1);
      sessionStorage.setItem("tooltipmsg",1)
    }
    setNavopen(false);
    setNavAnimation(true)
  };

  const navbarCloser = () => {
    setNavopen(true);
    // setNavAnimation(true)
  };

  const handleAnimationEnd = () => {
    console.log("end");

  }

  useEffect(() => {
    setWindowpath(window.location.pathname);
  }, []);
  return (
    <>
      {navopen && (
        <>
        {(sessionStorage.tooltipmsg!='1')&&(
          <div className="nav_tooltip_container">
            <div className="nav_tooltip_content">
              <div className="nav_tooltip_text"> Hey, use me to navigate!</div>
              {/* <div className="nav_tooltip_close_btn_holder">
                <button type="submit" className="nav_tooltip_close_btn">Okay</button>
              </div> */}
            </div>
          </div>
        )}
        <div className="navbar_open_holder" onClick={navbarOpener}>
          <img
            src={arrow_right}
            alt="opening icon"
            className="navbar_open_img"
          />
        </div>
        </>
      )}

      <div
        className={`navbar_container ${navopen ? "" : "nav_opening"} ${firstflag === 0 ? "nav_first_closed" : ""
          } ${navopen && firstflag === 1 ? "nav_closing" : ""}`}
      >
        <div className="navbar_title_holder">
          <TextEffect className="navbar_title" val="MENU" repeatFlag={true} intervalTime={5000} />

          <div className="navbar_close_holder" onClick={navbarCloser}>
            <img
              src={arrow_left}
              alt="closing icon"
              className="navbar_close_img"
            />
          </div>

          <div className="navbar_menu_container">
            <a
              href="/"
              className={`navbar_menu_holder ${windowpath === "/" ? "selected_menu" : ""
                } ${navAnimation ? "nav_animate" : ""}`}

            >
              <div className="navbar_menu_img_cont">
                <img src={menu1} alt="Home Icon" className="navbar_menu_img" />
              </div>
              <div className="navbar_menu_text">HOME</div>
            </a>

            <a
              href="/#about"
              onClick={navbarCloser}
              className={`navbar_menu_holder ${windowpath === "/#about" ? "selected_menu" : ""
                } ${navAnimation ? "nav_animate" : ""}`}
            >
              <div className="navbar_menu_img_cont">
                <img
                  src={menu2}
                  alt="About Icon"
                  className="navbar_menu_img"
                />
              </div>
              <div className="navbar_menu_text" >ABOUT</div>
            </a>

            <a
              href="/login"
              className={`navbar_menu_holder ${windowpath === "/login" ? "selected_menu" : ""
                } ${navAnimation ? "nav_animate" : ""}`}
            >
              <div className="navbar_menu_img_cont">
                <img
                  src={menu3}
                  alt="Login Icon"
                  className="navbar_menu_img"
                />
              </div>
              <div className="navbar_menu_text">LOGIN</div>
            </a>

            <a
              href="/events"
              className={`navbar_menu_holder ${windowpath === "/events" ? "selected_menu" : ""
                } ${navAnimation ? "nav_animate" : ""}`}
            >
              <div className="navbar_menu_img_cont">
                <img src={menu4} alt="Events Icon" className="navbar_menu_img" />
              </div>
              <div className="navbar_menu_text">EVENTS</div>
            </a>

            <a href='/contact' className={`navbar_menu_holder ${windowpath === '/contact' ? 'selected_menu' : ''} ${navAnimation ? "nav_animate" : ""}`}
              onAnimationEnd={() => {
                console.log("Animation ended for HOME menu");
                // Optionally reset the animation state if needed
                setNavAnimation(false);
              }}>
              <div className='navbar_menu_img_cont'>
                <img src={menu5} alt='Contact Icon' className='navbar_menu_img' />
              </div>
              <div className='navbar_menu_text'>CONTACT</div>
            </a>

            <a
              href="/team"
              className={`navbar_menu_holder ${windowpath === "/team" ? "selected_menu" : ""
                } ${navAnimation ? "nav_animate" : ""}`}
            >
              <div className="navbar_menu_img_cont">
                <img
                  src={menu6}
                  alt="Team Icon"
                  className="navbar_menu_img"
                />
              </div>
              <div className="navbar_menu_text">TEAM</div>
            </a>




          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
