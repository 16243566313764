import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ReqModal.css'
import axios from 'axios'
import { backend_path } from '../../Constants/backend';

function ReqModal({setRegFlag,onClose, eventId }) {
    const navigate=useNavigate();
    const [isLoading,setIsLoading]=useState(false)
    const [mate1Id, setMate1Id] = useState('')
    const [mate2Id, setMate2Id] = useState('')
    const [mate1Err, setMate1Err] = useState('')
    const [mate2Err, setMate2Err] = useState('')
    const validateForm = () => {
        if (mate1Id.length != 6 || isNaN(mate1Id.substring(3, 6)) || mate1Id.substring(0, 3).toLowerCase() != "cbz") {
            setMate1Err("Enter a valid cognoblaze id");
            return false;
        } else {
            setMate1Err('')
        }

        if (mate2Id.length != 6 || isNaN(mate2Id.substring(3, 6)) || mate2Id.substring(0, 3).toLowerCase() != "cbz") {
            setMate2Err("Enter a valid cognoblaze id");
            return false;
        } else {
            setMate1Err('')
        }
        if (mate1Id == mate2Id) {
            setMate2Err("Team mate 1 id and team mate 2 id must be unique")
            return false;
        }
        return true;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        // alert(mateId)
        if (!validateForm()) {
            setIsLoading(false)
            return;
        }
        if (window.confirm("Kindly check your team mate id before submitting")) {
            const res = await axios.post(`${backend_path}/checkMateId`, { event: eventId, mateId: mate1Id });
            if (res.data.status != "Success") {
                setIsLoading(false)
                setMate1Err(res.data.status)
                return;
            }
            const res1 = await axios.post(`${backend_path}/checkMateId`, { event: eventId, mateId: mate2Id });
            if (res1.data.status != "Success") {
                setIsLoading(false)
                setMate2Err(res1.data.status)
                return;
            }
            const res2 = await axios.post(`${backend_path}/regteamreqevent`, { event: eventId, mate1Id: mate1Id, mate2Id: mate2Id})
            // console.log(res2.data);
            if(res2.data.status=="repeated id"){
                setIsLoading(false)
                setMate2Err("Your id and teammate id must be unique");
            }else if(res2.data.status=="limit"){
                setIsLoading(false)
                setMate2Err("One of your teammates have already registered for one of the limited events")
                // alert("One of your teammates have already registered for one of the limited events")
            }
            else if(res2.data.status=="Success"){
                // alert("Registered Successfully..! Enjoy the events")
                onClose();
                setRegFlag();
            }
        }



    }
    return (
        <div className='req_modal_container'>
            <div className='req_modal'>
                <p className='req_close_btn' onClick={onClose}>X</p>
                <p className='req_modal_heading'>Team Mate Info</p>
                <p className='req_modal_desc'>Note : A team must contain three members enter the other two team mate id</p>
                <form className='req_modal_form' onSubmit={handleSubmit}>
                    <input type="text" className='req_modal_input' value={mate1Id} placeholder='Enter first team mate id' onChange={(e) => { setMate1Id(e.target.value) }} required />
                    {mate1Err && <p className='input_error_msg reg_modal_err'>{mate1Err}</p>}
                    <input type="text" className='req_modal_input' value={mate2Id} placeholder='Enter second team mate id' onChange={(e) => { setMate2Id(e.target.value) }} required />
                    {mate2Err && <p className='input_error_msg reg_modal_err'>{mate2Err}</p>}

                    {!isLoading ?
                        (<div className='reg_btn'>
                            <button type='submit' className='req_modal_submit'>Register</button>
                        </div>)
                        :(<p className='form_loading_text'>Loading...</p>)}
                </form>
            </div>
        </div>
    )
}
export default ReqModal;