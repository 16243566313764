import React,{useState,useEffect} from 'react';
import Event_card from "../../Widget/Event_card/Event_card";
import './Event.css';

import squarecsecar from '../../asset/squarecsecard.webp';
import ninja from '../../asset/ninja.webp';
import battleofbits from '../../asset/battleofbits.webp';
import cryptichunt from '../../asset/cryptichunt.webp';
import cssart from '../../asset/cssart.webp';
import connextion from '../../asset/connextion.webp';
import debug from '../../asset/debug.webp';
import paperpresentation from '../../asset/paperpresentation.webp';
import rebrandrumble from '../../asset/rebrandrumble.webp';
import sqlbingo from '../../asset/sqlbingo.webp';
import techescaperoom from '../../asset/techescaperoom.webp';

import ninjahover from '../../asset/ninjahover.webp';
import battleofbitshover from '../../asset/battleofbitshover.webp';
import cryptichunthover from '../../asset/cryptichunthover.webp';
import cssarthover from '../../asset/cssarthover.webp';
import connextionhover from '../../asset/connextionhover.webp';
import debughover from '../../asset/debughover.webp';
import paperpresentationhover from '../../asset/paperpresentationhover.webp';
import rebrandrumblehover from '../../asset/rebrandrumblehover.webp';
import sqlbingohover from '../../asset/sqlbingohover.webp';
import techescaperoomhover from '../../asset/techescaperoomhover.webp';

const TermsModal = ({ show, onClose }) => {
  if (!show) return null;
  return (
    sessionStorage.eventsvisit!='1' && (<div className="event_inst_modal">
      <div className="event_inst_modal_content" onClick={(e) => e.stopPropagation()}>
        <div className='event_inst_content_container'>
          <div className='event_inst_heading'>INSTRUCTIONS</div>
          <div className='event_inst_content'>
            <ul>
              <ol>
                <li>Participants must behave appropriately, respect other participants, follow the guidelines provided by coordinators, and cooperate with them.</li>
                <li>Cheating or malpractice in any event is strictly forbidden. Participants are not allowed to use ChatGPT, AI software, or internet resources unless explicitly permitted by the event coordinators.</li>
                <li>For "Battle of Bids" and "Cryptic Haunt with Ouija", teams can participate in only one of these events, not both.</li>
                <li>For other events running in parallel, participants may join various events running at once provided that they complete an event before moving onto the next.</li>
                <li>Participants must remain within the rooms or locations assigned for their events.</li>
                <li>Failure to follow any of these rules will result in immediate disqualification.</li>
                <li>Participants with any questions should contact the respective event coordinators for clarification.</li>
              </ol>
              <li>Please adhere to these rules to ensure a fair and smooth event.</li>
            </ul>
          </div>
        </div>
        <a className='event_inst_contact' href='/contact'>CONTACT US</a>
        <div className='event_inst_close_btn'>
              <button type="submit" onClick={onClose}>Close</button>
            </div>
        
      </div>
    </div>)
  );
};

function Event() {
  useEffect(() => {
    document.body.scrollTop=0;
    document.documentElement.scrollTop=0;
    setShowModal(true)
    }, [window.location]);

  const [showModal, setShowModal] = useState(false);

  return (
    <div className="event_page">
      <div className="event_heading"> iconic event</div>
      
      {/* <div className="event_page_card"> */}
        <div className='event_page_card_2'>
          <div className='event_card_1st'>
            <Event_card
              image={ninja}
              characterImage={ninjahover}
              eventTitle="NINJA CODING"
              bottomImage={squarecsecar}
             eventLink="/ninja"
            />
          </div>
         
        
          
        </div>
        <div>
          <div className='event_page_head2'>limited events</div>
          <div className='event_page_card_3'>
          <div className='event_card_2nd'>
            <Event_card
                image={cryptichunt}
              characterImage={cryptichunthover}
              eventTitle="CRYPTIC HAUNT "
              bottomImage={squarecsecar}
              eventLink="/cryptic"
            />
          </div>
          <div className='event_card_3rd'>
            <Event_card
               image={battleofbits}
              characterImage={battleofbitshover}
              eventTitle="BATTLE OF BIDS"
              bottomImage={squarecsecar}
              eventLink="/battle"
            />
          </div>
          </div>
          </div>
          <div className='event_page_head3'>general events </div>
          <div className='event_page_card_4'>
          <div className='event_card_4th'>
            <Event_card
               image={paperpresentation}
              characterImage={paperpresentationhover}
              eventTitle="PAPER PRESENTATION"
              bottomImage={squarecsecar}
              eventLink="/paperpresent"
            />
          </div>
            <div className='event_card_5th'> 
              <Event_card
                 image={sqlbingo}
                characterImage={sqlbingohover}
                eventTitle="SQL BINGO CHALLENEGE"
                bottomImage={squarecsecar}
                eventLink="/sqlbingo"
              />
            </div>
            <div className='event_card_6th'>
              <Event_card
                 image={cssart}
                characterImage={cssarthover}
                eventTitle="CSS ART CHALLENGE"
                bottomImage={squarecsecar}
                eventLink="/cssart"
              />
            </div>
            </div>
            <div className='event_page_card_5'>
            <div className='event_card_6th'>
              <Event_card
                 image={rebrandrumble}
                characterImage={rebrandrumblehover}
                eventTitle="REBRAND RUMBLE"
                bottomImage={squarecsecar}
                eventLink="/rebrand"
              />
            </div>
            <div className='event_card_6th'>
              <Event_card
                 image={techescaperoom}
                characterImage={techescaperoomhover}
                eventTitle="TECH ESCAPE ROOM"
                bottomImage={squarecsecar}
                eventLink="/techescape"
              />
            </div>
            <div className='event_card_6th'>
              <Event_card
                 image={connextion}
                characterImage={connextionhover}
                eventTitle="CONNEXION"
                bottomImage={squarecsecar}
                eventLink="/connexion"
              />
            </div>
            <div className='event_card_6th'>
              <Event_card
                 image={debug}
                characterImage={debughover}
                eventTitle="BUG BOUNTY BATTLE"
                bottomImage={squarecsecar}
                eventLink="/debug"
              />
            </div> 
            <TermsModal show={showModal} onClose={() => {
              setShowModal(false)
              sessionStorage.setItem("eventsvisit",1)
              }} />

          </div>
        </div>
      // </div>
    
  );
}

export default Event;
