import HOD from '../Assets/images/team-card-img.webp';
import jawahar from '../Assets/images/jawahar.webp';
import sathiyan from '../Assets/images/sathiyan.webp';
import menaka from '../Assets/images/menaka.webp';
import hema from '../Assets/images/hema.webp';
import sugan from '../Assets/images/sugan.webp';
import kavya from '../Assets/images/kavya.webp';
import yoga from '../Assets/images/yoga.webp';
import choudry from '../Assets/images/choudry.webp';
import Pink from '../Assets/images/team-card-bottom-pink.webp';
import Blue from '../Assets/images/team-card-bottom-blue.webp'; 

const Finalyear = [
    {
        teamImage: sathiyan,
        Teamcardbottom: Blue,
        role: "Secretary",
        name: "SATHIYAN R",
        linkedin:"https://www.linkedin.com/in/sathiyan-r/",
        showLinkedIn:"true" 
    },
    {
        teamImage: menaka,
        Teamcardbottom: Blue,
        role: "Secretary",
        name: "MENAKA J",
         linkedin:"https://www.linkedin.com/in/menaka-jayakumar-938000331",
        showLinkedIn:"true"
    },
    {
        teamImage: sugan,
        Teamcardbottom: Blue,
        role: "Treasurer",
        name: "SUGAN G",
         linkedin:"https://www.linkedin.com/in/sugan-g-642a3b24b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"

    },
    {
        teamImage: hema,
        Teamcardbottom: Blue,
        role: "Treasurer",
        name: "HEMA P",
         linkedin:"https://www.linkedin.com/in/hema-parthiban-56b927330?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: choudry,
        Teamcardbottom: Blue,
        role: "Logistics Manager",
        name: "CHOUDRY R",
         linkedin:"",
        // showLinkedIn:"false"
    },
    {
        teamImage: yoga,
        Teamcardbottom: Blue,
        role: "Student Growth Head",
        name: "YOGADHARSHINII T",
         linkedin:"https://www.linkedin.com/in/yogadharshinii-t-a8a002254",
        showLinkedIn:"true"
    },
    {
        teamImage: jawahar,
        Teamcardbottom: Blue,
        role: "Social Media Handler",
        name: "JAWAHAR RL",
         linkedin:"https://www.linkedin.com/in/jawahar-rl-489aa426a",
        showLinkedIn:"true"
    },
    {
        teamImage: kavya,
        Teamcardbottom: Blue,
        role: "Event Organiser",
        name: "KAVYA K",
         linkedin:"https://www.linkedin.com/in/kavya-k-59076b320?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    }
    
];

export default Finalyear;