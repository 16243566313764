import React from 'react';
import Teamscard from '../../Components/Teamscard/Teamscard';
import { TextEffect } from '../../Widget';
import './Teams.css';
import Staffdata from '../../Constants/Staffdata';
import Finalyear from '../../Constants/Finalyear';
import Thirdyear from '../../Constants/Thirdyear';
import Secondyear from '../../Constants/Secondyear';
import Devteam from '../../Constants/devteam';

function Teams() {
  return (
    // <div>
      <div className="team_background">
        <TextEffect
          className="teams-title"
          val="Teams"
          repeatFlag={true}
          intervalTime={5000}
        />
         <h2 className="teams-side-headings"> Staffs </h2>
        <div className="Team-cards">
          {Staffdata.map((teamMember, index) => (
            <Teamscard 
              key={index} // Use a unique key for each element
              teamImage={teamMember.teamImage} 
              Teamcardbottom={teamMember.Teamcardbottom} 
              role={teamMember.role} 
              name={teamMember.name} 
              fontcolor={teamMember.fontcolor}
              
            />
          ))}
        </div>
        <h2 className="teams-side-headings"> Final Year </h2>
        <div className="Team-cards">
          {Finalyear.map((teamMember, index) => (
            <Teamscard 
              key={index} // Use a unique key for each element
              teamImage={teamMember.teamImage} 
              Teamcardbottom={teamMember.Teamcardbottom} 
              role={teamMember.role} 
              name={teamMember.name} 
              fontcolor={teamMember.fontcolor}
              linkedin={teamMember.linkedin}
              showLinkedIn={teamMember.showLinkedIn}
            />
          ))}
        </div>
        <h2 className="teams-side-headings"> Third Year </h2>
        <div className="Team-cards">
          {Thirdyear.map((teamMember, index) => (
            <Teamscard 
              key={index} // Use a unique key for each element
              teamImage={teamMember.teamImage} 
              Teamcardbottom={teamMember.Teamcardbottom} 
              role={teamMember.role} 
              name={teamMember.name} 
              fontcolor={teamMember.fontcolor}
              linkedin={teamMember.linkedin}
              showLinkedIn={teamMember.showLinkedIn}
            />
          ))}
        </div>
        <h2 className="teams-side-headings"> Second Year </h2>
        <div className="Team-cards">
          {Secondyear.map((teamMember, index) => (
            <Teamscard 
              key={index} // Use a unique key for each element
              teamImage={teamMember.teamImage} 
              Teamcardbottom={teamMember.Teamcardbottom} 
              role={teamMember.role} 
              name={teamMember.name} 
              fontcolor={teamMember.fontcolor}
              linkedin={teamMember.linkedin}
              showLinkedIn={teamMember.showLinkedIn}
            />
          ))}
        </div>
        <h2 className="teams-side-headings"> Web Team </h2>
        <div className="Team-cards">
          {Devteam.map((teamMember, index) => (
            <Teamscard 
              key={index} // Use a unique key for each element
              teamImage={teamMember.teamImage} 
              Teamcardbottom={teamMember.Teamcardbottom} 
              role={teamMember.role} 
              name={teamMember.name} 
              fontcolor={teamMember.fontcolor}
              linkedin={teamMember.linkedin}
              showLinkedIn={teamMember.showLinkedIn}
            />
          ))}
        </div>
      </div>
    // </div>
  );
}

export default Teams;