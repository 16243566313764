import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { backend_path } from '../../Constants/backend';
import './OptionalModal.css'

const OptionalModal = ({ setRegFlag,onClose, eventId }) => {

    const navigate=useNavigate();
    const [mateId, setMateId] = useState('')
    const [errMsg, setErrMsg] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (mateId.length != 0) {
            if (mateId.length != 6 || isNaN(mateId.substring(3, 6)) || mateId.substring(0, 3).toLowerCase() != "cbz") {
                setErrMsg("Enter a valid cognoblaze id");
                return;
            } else {
                if (window.confirm("Kindly check your team mate id before submitting")) {
                    const res = await axios.post(`${backend_path}/checkMateId`, { event: eventId, mateId: mateId });
                    if (res.data.status != "Success") {
                        setErrMsg(res.data.status)
                        return;
                    }
                    setErrMsg('')
                    const res1 = await axios.post(`${backend_path}/regteamevent`, { event: eventId, mateId: mateId})
                    if(res1.data.status=="repeated id"){
                        setErrMsg("Your id and teammate id must be unique");
                        return;
                    }
                    else if (res1.data.status == "Success") {
                        // alert("Registerd Successfully..! Enjoy the events")
                        onClose();
                        setRegFlag();
                        // navigate("/event")
                    }
                }
            }
        }
        else {
            if (window.confirm("Are you sure you want to participate as a single member")) {
                setErrMsg('')
                const res = await axios.post(`${backend_path}/regteamevent`, { event: eventId, mateId: "cbz0000"})
                if (res.data.status == "Success") {
                    // alert("Registerd Successfully..! Enjoy the events")
                    onClose();
                    setRegFlag();
                    // navigate("/event")
                }
            }
        }


    }

    return (

        <div className='optional_modal_container'>
            <div className='optional_modal'>
                <p className='opt_close_btn' onClick={onClose}>X</p>
                <p className='optional_modal_heading'>Team Mate Info</p>
                <p className='optional_modal_desc'>Note : Team mate is optional</p>
                <form className='optional_modal_form' onSubmit={handleSubmit}>
                    <input type="text" value={mateId} className='optional_modal_input' placeholder='Enter team mate id' onChange={(e) => { setMateId(e.target.value) }} />
                    {errMsg && <p className='input_error_msg reg_modal_err'>{errMsg}</p>}
                    <button type='submit' className='optional_modal_submit' >Register</button>
                </form>
            </div>
        </div>

    )
}
export default OptionalModal;