import React ,{useEffect}from "react";
import "./Description.css";
import { sqlbingo } from "../../Constants/description";
import cup from "../../Assets/cup.webp";
import { HeroSection } from "../../Container";
import {sql } from "../../Constants/additionalcard"
import { Additionalcard } from "../../Widget";

const Sqlbingo = () => {
  useEffect(() => {
    document.body.scrollTop=0;
    document.documentElement.scrollTop=0;
    }, [window.location]);
  return (
    <>
    <HeroSection
    checker={sqlbingo.hero.checker}
    title1={sqlbingo.hero.title1}
    title2={sqlbingo.hero.title2}
    eventId={sqlbingo.hero.eventId}/>
    <div id="description" className="presentation-container">
      <div className="description_whole">
        <h1 className="events_desc_heading">{sqlbingo.description.title}</h1>
        <div className="description_section">
          <div className="glassmorphism-box">
            <div className="main_description_content">
              <p>{sqlbingo.description.content}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="rules_section">
        <h1 id="rules">{sqlbingo.rulesSchedule.title}</h1>
        <ul className="rules-list">
          {sqlbingo.rulesSchedule.points.map((point, index) => (
            <li key={index} className="rule-item">
              <span className="rule-index">Rule {index + 1} :</span> {point}
            </li>
          ))}
        </ul>

        <div className="des_time_venue">
          <span className="rule-index">
        TIME :</span>{sqlbingo.rulesSchedule.time}
          
          <div className="des_venue">
          <span className="rule-index">
            VENUE : </span>
          {sqlbingo.rulesSchedule.venue}
          </div>
        </div>
      </div>

      <div id="prize" className="prize_section">
        <div className="prize_content">
          <div className="prize_image">
            <img src={cup} alt="Prize" />
          </div>

          <div className="prize_text">
            <div className="prize_head">
            <h2 className="prize_head">PRIZE</h2>
            </div>
            <div className="des_amt">
            <p className="des_amt">{sqlbingo.prize}</p>
            </div>
            <div className="des_indian_rupee">
              <h className="des_indian_rupee">
              {sqlbingo.prize==="Trophy"?"":"Indian Rupees"}
              </h>
            </div>
          </div>
        </div>
      </div>
      <div className="description_contact">
        <div className="footer_heading">
          <h1 id="contact">CONTACT</h1>
        </div>
        <div className="footer_content">
          <div className="contact_item">
            <p>{sqlbingo.contact.name1}</p>
            <a href={`tel:${sqlbingo.contact.contact1}`} className="contact_link">
            {sqlbingo.contact.contact1}
            </a>
            <br />
            <a href={`mailto:${sqlbingo.contact.mail1}`} className="contact_link">
            {sqlbingo.contact.mail1}
            </a>
          </div>

          <div className="contact_item">
            <p>{sqlbingo.contact.name2}</p>
            <a href={`tel:${sqlbingo.contact.contact2}`} className="contact_link">
            {sqlbingo.contact.contact2}
            </a>
            <br />
            <a href={`mailto:${sqlbingo.contact.mail2}`} className="contact_link">
            {sqlbingo.contact.mail2}
            </a>
          </div>
        </div>
      </div>
    </div>
    <Additionalcard data={sql}/>
    </>
  );
};

export default Sqlbingo;
