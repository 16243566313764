import React ,{ useState ,useEffect }from 'react';
import './Dashboard.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { backend_path } from '../../Constants/backend';
import {eventNames} from '../../Constants/eventlist'

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [name,setName]=useState('');
  const [id,setId]=useState('');
  const [collegeName,setCollegeName]= useState('');
  const [selectedDates,setSelectedDates]= useState({oct21:0,oct22:0});
  const [email,setEmail]= useState('');
  const [qrcode,setQRcode]=useState('')
  const [accom,setAccom]=useState('')
  const [food,setFood]=useState('')
  const [events,setEvents]=useState({event1:0,event2:0,event3:0,event4:0,event5:0,event6:0,event7:0,event8:0,event9:0,event10:0})
  const navigate = useNavigate();

  const logout_handler=async()=>{
    console.log("out")
    const res= await axios.get(`${backend_path}/logout`,{},);
    console.log(res);
    if(res.data.status==="Success"){
      sessionStorage.clear()
      navigate("/")
    }
  }

  useEffect(() => {
    
    const fetchDashboard=async ()=>{
      const res=await axios.get(`${backend_path}/dashboard`);
      console.log(res.data);

      if(res.data.Error=="not authenticated"){
        console.log("Not logged in")
        navigate("/login")
      }
      
      // setEmail(res.data.participant.email)
      // setId(res.data.participant.id)
      // setName(res.data.participant.name)
      // setCollegeName(res.data.participant.clg)
      // setSelectedDates(res.data.participant.selectedDates)
      setEvents(res.data.participant.events)
      // setQRcode(res.data.participant.qr)
      setUserData(res.data.participant)
    }

    fetchDashboard(); 

    const scanData = () => {
      console.log("Scanning data..."); 
  
    };

    scanData(); 
  }, []); 
  
  if (!userData) {
    return <div>Loading...</div>; 
  }
  return (
    <div className="dashboard-container">
      <div className="dashboard-title">DASHBOARD</div>
      
      <div className="card-container">
    
        <div className="right-card">
          <img 
            src={`data:image/png;base64,${userData.qr}`}
            alt="QR Code" 
            className="qr-code"
          />
        </div>

   
        <div className="left-card">
          
      
            <div className="name_title">{userData.name}</div>
            
            <div className="info">{userData.dept}</div>
            <div className="info1">{userData.clg}</div>
           
          

         
          <div className="food-stay-component">
            <div className="details">
            <div className='detail_head'> CBZ ID:  <span className="detail_heading-gap"> </span>{userData.id}</div>
            {/* <div className='detail_head'> FOOD:  <span className="detail_heading-gap"> </span>{userData.food}</div> */}
            <div className='detail_head'> STAY:  <span className="detail_heading-gap"> </span>{userData.accom?"Yes" :"No"}</div>
            </div>
          </div>
        </div>
      </div>

      <div className='dash_selected_event'>
        <div className='dash_reg_events_holder'>
          <span className='dash_reg_event'>REGISTERED EVENTS</span>
          <div className='dash_events_list_container'>
          <ul className='dash_events_list'>
            
            {events.event1==1 && (<li >{eventNames.event1}</li>)} 
            {events.event2==1 && (<li >{eventNames.event2}</li>)} 
            {events.event3==1 && (<li >{eventNames.event3}</li>)} 
            {events.event4==1 && (<li >{eventNames.event4}</li>)} 
            {events.event5==1 && (<li >{eventNames.event5}</li>)} 
            {events.event6==1 && (<li >{eventNames.event6}</li>)} 
            {events.event7==1 && (<li >{eventNames.event7}</li>)} 
            {events.event8==1 && (<li >{eventNames.event8}</li>)} 
            {events.event9==1 && (<li >{eventNames.event9}</li>)} 
            {events.event10==1 && (<li >{eventNames.event10}</li>)} 
            
          </ul>
          </div>
        </div>
        </div>
      <div className="dashboard_button">
          <button className="dashboard_button_logout"  onClick={logout_handler}>logout</button>
      </div>

    </div>
  );
};

export default Dashboard;

