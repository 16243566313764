import belcy from '../Assets/images/belcy.webp';
import arjun from '../Assets/images/arjun.webp';
import mohana from '../Assets/images/mohana.webp';
import kanishka from '../Assets/images/kanishka.webp';
import monish from '../Assets/images/monish.webp';
import fajeela from '../Assets/images/fajeela.webp';
import gayathri from '../Assets/images/gayathri.webp';
import aishwarya from '../Assets/images/aishwarya.webp';
import jayasri from '../Assets/images/jayasri.webp';
import jalisha from '../Assets/images/jalisha.webp';
import hariraj from '../Assets/images/hariraj.webp';
import guru from '../Assets/images/guru.webp';
import sriram from '../Assets/images/sriram.webp';
import jawahar from '../Assets/images/jawahar.webp';
import sathiyan from '../Assets/images/sathiyan.webp';
import solai from '../Assets/images/solai.webp';

import Blue from '../Assets/images/team-card-bottom-blue.webp';

const Devteam = [
    {
        teamImage: solai,
        Teamcardbottom: Blue,
        role: "Designer",
        name: "Solai Dhanush V",
        linkedin:"https://arjunprakash22.github.io/solai_dhanush/Dhanush-portfolio/",
        showLinkedIn:"true" 
    },
    {
        teamImage: belcy,
        Teamcardbottom: Blue,
        role: "Full Stack",
        name: "Arockiya Belcy VS",
         linkedin:"https://www.linkedin.com/in/arockiya-belcy-vs",
        showLinkedIn:"true"
    },
    {
        teamImage: arjun,
        Teamcardbottom: Blue,
        role: "Full Stack",
        name: "Arjun Prakash AS",
         linkedin:"https://www.linkedin.com/in/arjun-prakash-sivakumar-ap?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: gayathri,
        Teamcardbottom: Blue,
        role: "Frontend",
        name: "Gayathiri NS",
        linkedin:"https://www.linkedin.com/in/gayathirins",
        showLinkedIn:"true"
    },
    {
        teamImage: fajeela,
        Teamcardbottom: Blue,
        role: "Frontend",
        name: "Ummal Fajeela N",
         linkedin:"https://www.linkedin.com/in/n-ummal-fajeela-a63613283",
        showLinkedIn:"true"
    },
    {
        teamImage: aishwarya,
        Teamcardbottom: Blue,
        role: "Frontend",
        name: "Aishwarya B",
         linkedin:"https://www.linkedin.com/in/aishwarya-b-7b8537278?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: jalisha,
        Teamcardbottom: Blue,
        role: "Frontend",
        name: "Jalisha Joans J",
         linkedin:"",
        // showLinkedIn:"true"
    },
    {
        teamImage: jayasri,
        Teamcardbottom: Blue,
        role: "Frontend",
        name: "Jayasri R",
         linkedin:"https://www.linkedin.com/in/jayasri-r-39b6b3280?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: hariraj,
        Teamcardbottom: Blue,
        role: "Frontend",
        name: "Hariraj K",
         linkedin:"https://www.linkedin.com/in/hariraj-k-88575a264/?originalSubdomain=in",
        showLinkedIn:"true"
    },
    {
        teamImage: kanishka,
        Teamcardbottom: Blue,
        role: "Frontend",
        name: "Kanishka M",
         linkedin:"https://www.linkedin.com/in/kanishka-m-5655472a0",
        showLinkedIn:"true"
    },
    {
        teamImage: mohana,
        Teamcardbottom: Blue,
        role: "Frontend",
        name: "Mohanamala J",
         linkedin:"https://www.linkedin.com/in/mohana-mala-025a88245",
        showLinkedIn:"true"
    },
    {
        teamImage: monish,
        Teamcardbottom: Blue,
        role: "Frontend",
        name: "Monishwaran S",
         linkedin:"https://www.linkedin.com/in/monishwaran-s-7783992a6?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: guru,
        Teamcardbottom: Blue,
        role: "Full Stack",
        name: "Guru Srinivasan P",
         linkedin:"https://www.linkedin.com/in/guru-srinivasan-73a39a2a6?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: sriram,
        Teamcardbottom: Blue,
        role: "Full Stack",
        name: "Sriram K",
         linkedin:"https://www.linkedin.com/in/sri-ram-1459192a2?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: jawahar,
        Teamcardbottom: Blue,
        role: "Frontend",
        name: "JAWAHAR RL",
         linkedin:"https://www.linkedin.com/in/jawahar-rl-489aa426a",
        showLinkedIn:"true"
    }
];

export default Devteam;