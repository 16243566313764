import React, { useState,useEffect } from 'react';
import './Login.css';
import { Link } from 'react-router-dom';
import cseasso from '../../Assets/images/cseassologo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'
import { backend_path } from '../../Constants/backend';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  axios.defaults.withCredentials = true;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [usrerror, setUsrerror] = useState("");
  const [pswderror, setPswderror] = useState("");
  const [loading,setLoading]=useState(false)

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    username: '',
    password: '',
  });

  const [showPassword, setShowPassword] = useState(false);


  const validateForm = () => {
    setEmail(email.trim());
    setPassword(password.trim());
    setUsrerror('');
    setPswderror("")
    let uflag=true;
    let pflag=true;

    if (!email) {
      setUsrerror('Enter Email');
      uflag = false;
    }else if(!email.includes("@")){
      setUsrerror("Invalid Email");
      uflag = false;
    }

    if (!password) {
      setPswderror("Enter Password")
      pflag = false;
    }

    return uflag&&pflag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true)
      console.log('Form Submitted', formData);
      console.log('Form Submitted', formData.username, formData.password);
      const res = await axios.post(`${backend_path}/login`, {
        email:email,
        pswd:password,
      })

      console.log(res.data)
      if(email==='cseasso.acgcet@gmail.com'&& res.data.pswd_status){
        console.log("admin pass matched");
        navigate("/admin-panel");
      }
      else if (res.data.pswd_status) {
        sessionStorage.setItem("email",res.data.email);
        navigate("/dashboard", { state: { id: res.data.id} });
      }
      else if(res.data.username_not_found){
        setUsrerror("Email not Registered")
        setLoading(false)
      }else {
        setPassword("");
        setPswderror("Incorrect password");
        setLoading(false)
      }
    } else {
      console.log('Form has errors.');
    }
  };

  useEffect(()=>{
    console.log("started!")
    axios.get(`${backend_path}/login_verification`)
    .then((res)=>{
      console.log(res)
      if(res.data.token_status==="okay"){
        navigate("/dashboard")
      }
    })
  },[])

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
        <div className="login-form-text">
          <h2 className="login-h2">Login</h2>
          {/* <p className="login-p">Please enter your Username & Password</p> */}
          <form onSubmit={handleSubmit} className="login-form">
            <div className="form-group">
              <input
                className="login_form-input"
                type="text"
                id="username"
                name="username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <p className='input_error_msg'>{usrerror}</p>
            </div>

            <div className="form-group password-group">
              <input
                className="login_form-input"
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <span className="password-icon" onClick={togglePasswordVisibility}>
                <FontAwesomeIcon style={{ color: "#fff" }} icon={showPassword ? faEyeSlash : faEye} />
              </span>
              </div>
              <p className='input_error_msg'>{pswderror}</p>
            

            <div className="q-links">
              <a className="q-link" href="/register">
                New user?
              </a>
              <a className="q-link" href="/forgot">
                Forgot password?
              </a>
            </div>

            <div className='login_butt_holder'>
            {!loading ?
            (<button type="submit" className="login-button">
              Login
            </button>)
            :(<p className='form_loading_text'>Loading...</p>)}
            </div>
          </form>
        </div>

        <div className="login-form-img-cont">
          <img className="login-form-img" src={cseasso} alt="CSE Association" />
        </div>
      </div>
    </div>
  );
};

export default Login;
