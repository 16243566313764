import React, { useState, useEffect, useRef } from 'react';
import './TextEffect.css';

const TextEffect = ({ val, className,intervalTime,repeatFlag }) => {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const [nameText, setNameText] = useState(val); // Initial text
  const iterationRef = useRef(0); // Ref to store iteration
  const intervalRef = useRef(null);

  const handleMouseEnter = () => {
    clearInterval(intervalRef.current); // Clear any existing interval
    iterationRef.current = 0; // Reset iteration

    intervalRef.current = setInterval(() => {
      setNameText((prevText) =>
        prevText
          .split("")
          .map((letter, index) => {
            if (index < iterationRef.current) {
              return val[index]; // Use the original text from 'val' prop
            }
            return letters[Math.floor(Math.random() * 26)]; // Random letters
          })
          .join("")
      );

      iterationRef.current += 1 / 3; // Increment iteration

      if (iterationRef.current >= val.length) {
        clearInterval(intervalRef.current); // Stop the effect once completed
      }
    }, 50);
  };

  useEffect(() => {
    handleMouseEnter()
    let textInterval
    
    if(repeatFlag){
    // console.log(intervalTime);
        const textInterval = setInterval(handleMouseEnter, intervalTime);
    }
    return () => {
      clearInterval(intervalRef.current); // Cleanup interval on component unmount
    //   if (repeatFlag) {
        clearInterval(textInterval)
    //   }
    };
  }, []);

  return (
    <div className={className} data-value={val} onMouseEnter={handleMouseEnter}>
      {nameText}
    </div>
  );
};

export default TextEffect;
