import alakesh from '../Assets/images/alagesh.webp';
import deepak from '../Assets/images/deepak.webp';
import kalai from '../Assets/images/kalai.webp';
import narmatha from '../Assets/images/narmatha.webp';
import thamarai from '../Assets/images/thamarai.webp';
import alageshwari from '../Assets/images/alageshwari.webp';
import ashwini from '../Assets/images/ashwini.webp';
import niveda from '../Assets/images/niveda.webp';
import Blue from '../Assets/images/team-card-bottom-blue.webp';

const Secondyear = [
    {
        teamImage: alakesh,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "ALAKESHVARKUMAR R",
         linkedin:"https://www.linkedin.com/in/alakeshvarkumar?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: deepak,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "DEEPAK M",
         linkedin:"https://www.linkedin.com/in/deepak-mariappan-05a563292?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: kalai,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "KAVICHEZHIAN T",
         linkedin:"https://www.linkedin.com/in/kavichezhian-t-502106293?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: thamarai,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "THAMARAI NAGASAMY K",
         linkedin:"https://www.linkedin.com/in/thamarai-nagasamy-0786672a5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: alageshwari,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "ALAGESHWARI M",
         linkedin:"https://www.linkedin.com/in/alageshwari?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: ashwini,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "ASHWINI D",
         linkedin:"https://www.linkedin.com/in/ashwini-ashwi-1b3b5832a",
        showLinkedIn:"true"
    },
    {
        teamImage: narmatha,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "NARMATHA R",
         linkedin:"https://www.linkedin.com/in/narmatha-r-a79a882a7?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: niveda,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "NIVEDHA B",
         linkedin:"https://www.linkedin.com/in/nivedha-b-564710291",
        showLinkedIn:"true"
    }
];

export default Secondyear;