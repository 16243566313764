import HOD from '../Assets/images/team-card-img.webp';
import guru from '../Assets/images/guru.webp';
import sriram from '../Assets/images/sriram.webp';
import sudharshan from '../Assets/images/sudharshan.webp';
import kavitha from '../Assets/images/kavitha.webp';
import ganikasri from '../Assets/images/ganikasri.webp';
import rishika from '../Assets/images/rishika.webp';
import kesavapriya from '../Assets/images/kesavapriya.webp';
import iyappan from '../Assets/images/iyappan.webp';
import Blue from '../Assets/images/team-card-bottom-blue.webp';

const Thirdyear = [
    {
        teamImage: guru,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "GURU SRINIVASAN P",
         linkedin:"https://www.linkedin.com/in/guru-srinivasan-73a39a2a6?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: iyappan,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "IYYAPPAN A",
         linkedin:"https://www.linkedin.com/in/iyappan-ananthavel-313613277?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: sriram,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "SRIRAM K",
         linkedin:"https://www.linkedin.com/in/sri-ram-1459192a2?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: sudharshan,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "SUDHARSAN E",
         linkedin:"https://www.linkedin.com/in/sudharsan-esakkidurai-1820a1297?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: ganikasri,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "GANIKA SRI T",
         linkedin:"https://www.linkedin.com/in/ganika-sri-458a1a26a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: kesavapriya,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "KESAVA PRIYA G",
         linkedin:"https://www.linkedin.com/in/kesava-priya-aa92b1295?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: kavitha,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "KAVITHA K",
         linkedin:"https://www.linkedin.com/in/kavitha-kathiravan-6b0538258?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    },
    {
        teamImage: rishika,
        Teamcardbottom: Blue,
        role: "Co-ordinator",
        name: "RISIKA S",
         linkedin:"https://www.linkedin.com/in/risika-k-85077226b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        showLinkedIn:"true"
    }
];

export default Thirdyear;