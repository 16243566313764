import React, { useEffect, useState } from "react";
import "./Home.css";
import left from "../../Assets/images/left.webp";
import home_event_img from "../../Assets/pictures/Homeevents.webp";
import mobabt from '../../Assets/images/abt-mob1.webp'
import right from "../../Assets/images/right.webp";
import right1 from "../../Assets/images/right1.webp";
import abtbg from "../../Assets/images/abt-bg.webp";
import cselogo from "../../Assets/images/cseassologo.webp";
import { TextEffect } from "../../Widget";
import { Timer } from "../../Container";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate= useNavigate();
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [isMovingLeft, setIsMovingLeft] = useState(false);

  const handlebuttonclick=()=>{
    navigate("/events")
  }

  useEffect(() => {
    const handleScroll = () => {
      // Check the scroll position
      if (window.scrollY > window.innerHeight * 1.5) {
        setIsAboutVisible(true);
        setIsMovingLeft(false);
      } else {
        setIsAboutVisible(false);
        // setIsMovingLeft(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="home-sec">
      {/* <div> */}
      <Timer />
      {/* <TextEffect className="name" val="COGNOBLAZE" repeatFlag={true} intervalTime={5000}/> */}
      {/* </div> */}
      <div className="landing-page">
        <div className="fixed-background" />

        <div
          className={`background-overlay ${
            isMovingLeft ? "transition-left" : "transition-right"
          }`}
        />

        <div className={`left-image ${isAboutVisible ? "move-right" : ""}`}>
          {/* <img
    src={left}
    alt="Left Side"
    className={`left-image ${isAboutVisible ? "move-right" : ""}`}
  /> */}
          {/* <div className="centered-text">Your Text Here</div> */}
          {/* <div className="left-image"> */}
          <img src={cselogo} className="left-image-logo" />
          {/* </div> */}
          {/* <TextEffect className="left-image-head" val="CSE ASSOCIATION" repeatFlag={true} intervalTime={5000}/> */}
          <p className="left-image-head">CSE ASSOCIATION</p>
          <TextEffect
            className="left-image1"
            val="COGNO"
            repeatFlag={true}
            intervalTime={5000}
          />
          {/* <p className="left-image1">COGNO</p> */}
          <p className="home_text_cont">
            <TextEffect
            className="left-image2"
            val="BLAZE'24"
            repeatFlag={true}
            intervalTime={4000}
            />
            <div className="home_text">
              <TextEffect 
              className="home_text_oct" 
              val="OCT"
              repeatFlag={true}
              intervalTime={3000}
              />
              <TextEffect 
              className="home_text_oct" 
              val="21"
              repeatFlag={true}
              intervalTime={3000}
              />
            </div>
          </p>
        </div>

        <img
          src={right}
          alt="Right Side"
          className={`right-image ${isAboutVisible ? "move-right" : ""}`}
        />
        <img
          src={right1}
          alt="Right Side"
          className={` mob-bg ${isAboutVisible ? "move-right" : ""}`}
        />

        <div  className={`about-section ${isAboutVisible ? "slide-in" : ""}`}>
          <div className="abt-sec-cont">
            <img className="abt-sec-img" src={abtbg} />
            <img className="abt-sec-img1" src={mobabt} />
            <div className="abt-sec-text">
              <h2 className="abt-sec-h2">ABOUT US</h2>
              <p id="about" className="abt-sec-p">
              The Department of Computer Science, established in 2001, focuses on enhancing student skills through workshops and symposiums. With a dedicated faculty, the department fosters academic growth and connects students with the broader research community. Its annual event, <b>Cognoblaze</b>, is an exciting inter-college symposium that unites students for a day of technical and non-technical competitions, encouraging innovation, teamwork, and creativity. Through coding duels, design challenges, and more, Cognoblaze serves as a platform for showcasing talent, fostering collaboration, and celebrating the future of technology and innovation.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="home_event_container">
            <div className="home_glass_box">
            <div className="event_image">
                <img
                  className="home_event_img"
                  src={home_event_img}
                  alt="home event"
                />
              </div>
              <div className="home_event_content">
                <div className="home_event_heading">
                CHECK OUT THE EVENTS
                </div>
                <div className="home_event_content_main">
              
                Explore the exciting events of the ACGCET CSE Inter-College Symposium. From coding challenges to technical paper presentations, engage with cutting-edge technologies, and collaborate with peers. Don't miss out on the opportunity to learn, compete, and innovate!
             
                </div>
                <div className="explore_button">
                <button onClick={handlebuttonclick}>Explore here!</button>
                </div>
              </div>
             
            </div>
          </div>
    </div>
  );
};

export default Home;
