import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './forgot.css';
import rightLogo from '../../Assets/images/cse_asso_logo.webp'; 
import { TextEffect } from '../../Widget';
import { backend_path } from "../../Constants/backend";
import axios from 'axios';

function ForgotPassword() {
  const [username, setUsername] = useState('');
  const [otp, setOtp] = useState(''); // New state variable for OTP
  const [errorMessage, setErrorMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [otpsent,SetOtpsent]=useState(false)
  const [loading,setLoading]=useState(false)
  const navigate = useNavigate(); 

  // Form Validation Function
  const validateForm = () => {
    const errors = {};
    if (username.trim().length === 0) {
      errors.username = 'Enter Email';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle Form Submission for sending OTP
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    
    if (validateForm()){
      console.log(username)
      setLoading(true)
      const errors = {};
      const res=await axios.post(`${backend_path}/sendotp`,{email:username})
      // console.log(res.data)
      if(res.data.status==="Email not found"){
        errors.username = res.data.status
        setFormErrors(errors);
        setLoading(false)
      }
      else if(res.data.status=="success"){
        SetOtpsent(true)
        setLoading(false)
      }else{
        alert("Oops something went wrong...Try again later!")
        navigate("/login")
      }
    };

    // TODO: Implement your logic to send OTP here.
    // e.g., make an API call to send the OTP to the user's email.
    // On success, you might want to display the OTP input field.
  };

  // Handle OTP Submission
  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    // TODO: Implement your logic to verify the OTP here.
    // e.g., make an API call to verify the OTP.
    if (otp.trim().length === 0) {
      setErrorMessage('Please enter the OTP');
      return;
    }else{
      const res=await axios.post(`${backend_path}/verifyotp`,{email:username,otp:otp})
      // console.log(res.data);
      if(res.data.status=="success"){
        sessionStorage.setItem("email",username)
        navigate("/reset")
      }else{
        alert("Wrong OTP")
        navigate("/login")
      }
    }
    

    // If OTP verification is successful
    // navigate to the next page, e.g., password reset page
    // navigate('/reset-password', { state: { username } });
  };

  return (
 <div className='forgot-container'>
      <div className='forgot-password-container'>
        <div className="form-container">
            <TextEffect className='forget-h2' val="FORGOT" intervalTime={5000} repeatFlag={true}/>
            <TextEffect className='forget-h2' val="PASSWORD" intervalTime={10000} repeatFlag={true}/>
            <p className= 'forget-p'>Please Enter your Username</p>
          { !otpsent &&
          (<form onSubmit={handleForgotPassword} className='forget-form'>
            <div className="input-group">
              <input
                className='forget_form-input'
                name="userName"
                id="userName"
                type="email"
                value={username}
                onChange={(e) => setUsername(e.target.value.trim())}
                placeholder="Email"
              />
              {formErrors.username && <span className='input_error_msg'>{formErrors.username}</span>}
            </div>
            {errorMessage && <p className='input_error_msg'>{errorMessage}</p>}
            {!loading ?
            (<button className="button-forgot" type="submit">Send Otp</button>)
            :(<p className='form_loading_text'>Loading...</p>)
            }
          </form>)
          }

          {/* OTP Input Field */}
          {otpsent &&
          (<form onSubmit={handleOtpSubmit} style={{ marginTop: '20px' }}>
            <div className="input-group">
              <input
                name="otp"
                id="otp"
                className='forget_form-input'
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value.trim())}
                placeholder="Enter OTP"
              />
            </div>
            {errorMessage && <p className='input_error_msg'>{errorMessage}</p>}
            {!loading ?
            (<button className="button-forgot" type="submit">Submit Otp</button>)
            :(<p className='form_loading_text'>Loading...</p>)
            }
          </form>)
          }
          </div>
        <div className='top_Logo'>
          <img className='right-logo' src={rightLogo} alt="Right Logo" />
        </div>
      </div>
      </div>
    
  );
}

export default ForgotPassword;