import React from "react";
import "./Footer.css";
import {useNavigate } from "react-router-dom"
import {
  FaInstagram,
  FaEnvelope,
  FaLinkedin,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from "react-icons/fa";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const navigate=useNavigate()
  const handleregbtn=()=>{
    navigate("/register")
  }
  return (
    <>
      

      <div className="footer_outer_wrap">
        <div className="footer_left_right">
          <div className="footer_left_sec">
            <div className="footer_reach_sec">
              <h2 className="footer_reach_head">Reach Us</h2>
            </div>
            <div className="footer_icon_holder">
              <div className="footer_icons">
                <div className="insta-hold">
                  <a
                    href="https://www.instagram.com/acgcet_cse_asso/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-logo"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
                <div className="mail-hold">
                  <a
                    href="mailto:cseasso.acgcet@gmail.com"
                    className="footer-logo"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </div>
                <div className="linkedin-hold">
                  <a
                    href="https://www.linkedin.com/in/cse-association-96a731325/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-logo "
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer_regbtn_holder">
              <div className="footer_regbtn_sec">
                <button onClick={handleregbtn} className="footer_regbtn">Register now</button>
              </div>
            </div>
          </div>
          <div className="footer_right_sec">
            <div className="footer_contact_holder">
              <h2 className="footer_contact_head">Contact</h2>
            </div>

            <div className="footer_phone_holder">
              <div className="footer_phone_sec">
                <p>
                  <a href="tel:9003821773" className="footer-phone-link">
                    <FaPhoneAlt className="footer-phone-icon" />Jawahar RL: 9003821773
                  </a>
                </p>
              </div>
              <div className="footer_phone_sec">
                <p>
                  <a href="tel:9489170204" className="footer-phone-link">
                    <FaPhoneAlt className="footer-phone-icon" />Kavya K: 9489170204
                  </a>
                </p>
              </div>
            </div>

            <div className="footer_address_holder">
              <div className="footer_address_sec">
                <p className="footer-address-p">
                  <a
                    href="https://maps.app.goo.gl/aHcHxxApCpmMqsoRA"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-map-logo"
                  >
                    <FaMapMarkerAlt />
                    <span className="footer-clg-address">
                      Alagappa Chettiar Government College of Engineering and
                      Technology, Karaikudi-630003. Sivagangai, Tamilnadu.
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_copyri8">
          <p className="footer_copyri8_info">&copy;ACGCET CSE Association</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
