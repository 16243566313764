import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HeroSection.css";
import leftasteroid from "../../Assets/pictures/asteroid_2.webp";
import rightasteroid from "../../Assets/pictures/asteroid_1.webp";
import astronaut from "../../Assets/pictures/astro.webp";
import navlogo from "../../Assets/pictures/logo.webp";
import { Link } from "react-router-dom";
import { OptionalModal,ReqModal } from "../../Components";
import axios from "axios";
import { backend_path } from "../../Constants/backend";

const HeroSection = ({checker,title1,title2,eventId}) => {
  const navigate=useNavigate()
  const [event3Cnt,setEvent3Cnt]=useState('Login')
  const [event4Cnt,setEvent4Cnt]=useState('Login')
  const [showOptModal,setShowOptModal]=useState(false)
  const [showReqModal,setShowReqModal]=useState(false)
  const [auth,setAuth]=useState(0);
  const [register_flag,setRegister_flag]=useState(0)

  const handleRegFlag =() =>{
    setRegister_flag(1);
  }

  const handleregister =async ()=>{
    if(auth==0){
      alert("You haven't logged in...")
      navigate("/login")
    }else{
      if(checker==0){
        if(window.confirm("Are you sure, you want to register this event ?")){
          const res=await axios.post(`${backend_path}/regevent`,{event:eventId});
          if (res.data.status == "Success") {
            // alert("Registerd Successfully..! Enjoy the events")
            setRegister_flag(1)
          }
        }
      }
      else if(checker==1){
        setShowOptModal(true);
        // return <OptionalModal eventId={eventId}/>
      }else if(checker==2){
        setShowReqModal(true);
      }
    }
      
  }

  useEffect(()=>{
      const fetchData= async ()=>{
        try{
          const res=await axios.post(`${backend_path}/getregonload`,{event:eventId});
          if(res.data.reg==0 || res.data.reg==1){
            setAuth(1)
          }
          if(res.data.reg==1){
            setRegister_flag(1)
            console.log("registered");
            
          }

          const teams=await axios.post(`${backend_path}/getteamslot`,{event:eventId});
          
          if(teams.data.seat_cnt!=null){
            if(eventId==3){
              setEvent3Cnt(8-teams.data.seat_cnt);
            }else if(eventId==4){
              setEvent4Cnt(10-teams.data.seat_cnt);
            }
          }

        }catch(err){
          console.error(err);
          
        }
      }
      fetchData();
  },[])
  


  const handleOptModalClose=()=>{
    setShowOptModal(false)
  }
  const handleReqModalClose=()=>{
    setShowReqModal(false)
  }

  return (
    <>
        
        {showOptModal && <OptionalModal setRegFlag={handleRegFlag} onClose={handleOptModalClose} eventId={eventId}/>}
        {showReqModal && <ReqModal setRegFlag={handleRegFlag} onClose={handleReqModalClose} eventId={eventId}/>}
      <div className="hero_sec_top_wrapper">
        <div className="left_asteroid">
          <img src={leftasteroid} className="left_ast_img" alt="leftasteroid" />
        </div>
        <div className="right_asteroid">
          <img
            src={rightasteroid}
            className="right_ast_img"
            alt="leftasteroid"
          />
        </div>

      <div className="desc_nav_cont">
        <div className="desc_nav_holder">
          <div className="desc_nav_part1">
            <div className="desc_nav_part1_content">
              <a href="#description" className="desc_nav_part1_link">DESCRIPTION</a>
            </div>
            <div className="desc_nav_part1_content">
              <a href="#rules" className="desc_nav_part1_link">RULES</a>
            </div>
          </div>
          <span className="desc_nav_logo">
            <Link to="/" className="desc_nav_link">
            <img src={navlogo} className="desc_nav_logo_img" />
            </Link>
          </span>
          <div className="desc_nav_part2">
            <div className="desc_nav_part2_content">
              <a href="#prize" className="desc_nav_part2_link">PRIZE</a>
            </div>
            <div className="desc_nav_part2_content">
              <a href="#contact" className="desc_nav_part2_link">CONTACT</a>
            </div>
          </div>
        </div>
      </div>

      <div className="hero_sec_holder">
        {(eventId===3 || eventId===4) &&(<span className="hero_sec_event_tag">LIMITED EVENT</span>)}
        {(eventId===9) &&(<span className="hero_sec_event_tag">ICONIC EVENT</span>)}
        <span className="hero_sec_heading_up">{title1}</span>
        <span className="hero_sec_heading_down">{title2}</span>
        <div className="hero_sec_astro_holder">
          <img src={astronaut} className="hero_sec_astro_img" />
        </div>
        <div className="hero_register_holder">
          {(eventId===4) && (<div className="team_slot_text">Teams slots left: {event4Cnt}</div>)}
          {(eventId===3) && (<div className="team_slot_text">Teams slots left: {event3Cnt}</div>)}
          {register_flag===1 && (<div className="hero_registered" >Registered</div>)}
          {register_flag===0 && (((eventId===4 && event4Cnt<=0)||(eventId===3 && event3Cnt<=0))?<div className="hero_registered" >Missed It!</div>:<button className="hero_reg_btn" onClick={handleregister}>Register</button>)}

        </div>
      </div>
      </div>
    </>
  );
};

export default HeroSection;
