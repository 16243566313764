import React, { useState, useEffect } from "react";
import "./Timer.css";
import { TextEffect } from "../../Widget";

import clglogo from "../../Assets/Timer/clglogo.webp";
import cseasso from "../../Assets/Timer/cse asso 1.webp";
import timerdays from "../../Assets/Timer/ellipsedays.webp";
import timerhours from "../../Assets/Timer/ellipsehours.webp";
import timermins from "../../Assets/Timer/ellipsemin.webp";
import timersecs from "../../Assets/Timer/ellipsesec.webp";
import shadow from "../../Assets/Timer/bottom-shadow.webp";

const Timer = () => {
  const targetDate = new Date("2024-10-21T00:00:00").getTime();

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });

      if (distance < 0) {
        clearInterval(interval);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <>
      <div className="timer-outer">
        <div className="timer-container">
          <div className="timer-nav">
            <div className="timer-asso">
              <img src={cseasso} alt="asso" />
            </div>
            <div className="timer-clglogo">
              <img src={clglogo} alt="clg" />
            </div>
          </div>

          <div className="timer-hours">
            <div className="hours-cont">
              <img src={timerhours} alt="hours" />
              <div className="hours-num-word">
                <p className="hours-num">{timeLeft.hours}</p>
                <p className="hours-text">HOURS</p>
              </div>
            </div>
          </div>
          <div className="timer-heart">
            <div className="timer-mins">
              <div className="mins-cont">
                <img src={timermins} alt="mins" />
                <div className="mins-num-word">
                  <p className="min-num">{timeLeft.minutes}</p>
                  <p className="min-text">MIN</p>
                </div>
              </div>
            </div>
            <div className="days-sec">
              <div className="timer-days">
                <div className="days-cont">
                  <img className="days-img" src={timerdays} alt="days" />
                  <div className="days-num-word">
                    <p className="days-num">{timeLeft.days}</p>
                    <p className="days-text">DAYS</p>
                  </div>
                  <img className="shadow" src={shadow} alt="days" />
                </div>
              </div>

              <div className="timer-secs">
                <div className="secs-cont">
                  <img src={timersecs} alt="secs" />
                  <div className="secs-num-word">
                    <p className="secs-num">{timeLeft.seconds}</p>
                    <p className="secs-text">SEC</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="timer-bottom">
            <TextEffect
              className="timer-heading-part1"
              val="COGNOBLAZE'24"
              repeatFlag={true}
              intervalTime={8000}
            />
            <div className="timer-space">&nbsp;&nbsp;&nbsp;&nbsp;</div>

            <TextEffect
              className="timer-heading-part2"
              val="OCT 21"
              repeatFlag={true}
              intervalTime={8000}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Timer;
