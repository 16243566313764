import React, { useState, useRef } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import './Register.css';
import logo from '../../Assets/images/cseassologo.webp';
import qrImage from '../../Assets/payment_qr.webp';
import cloud from '../../Assets/upload-icon.webp'
import axios from 'axios'
import { backend_path } from '../../Constants/backend';
import { TextEffect } from '../../Widget';

const Register = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [gender, setGender] = useState('');
    const [collegeName, setCollegeName] = useState('');
    const [referralId, setReferralId] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [city, setCity] = useState('');
    const [year, setYear] = useState('');
    const [departmentName, setDepartmentName] = useState('');
    const [foodPreference, setFoodPreference] = useState('Veg');
    const [accommodation, setAccommodation] = useState('0');
    const [receiptImage, setReceiptImage] = useState(null);// For receipt upload
    const fileInputRef = useRef(null);  // Define the fileInputRef
    const mobileRef = useRef(null)
    const emailRef = useRef(null)
    const refcodeRef = useRef(null)
    const passwordRef = useRef(null)
    const [mobileErrMsg, setMobileErrMsg] = useState('')
    const [passwordErrMsg, setPasswordErrMsg] = useState('')
    const [selectedFileName, setSelectedFileName] = useState('')
    const [fileDetails, setFileDetails] = useState({})
    const [transId, setTransId] = useState('')
    const [refCodeMsg, setRefCodeMsg] = useState('')
    const [EmailMsg, setEmailMsg] = useState('')
    const [loading,setLoading]=useState(false)

    const validateMobileNumber = (number) => {
        const regex = /^[6-9]\d{9}$/;
        return regex.test(number)

    }


    const handlePassword = (e) => {
        const confirmPasswordInput = e.target.value;
        setConfirmPassword(confirmPasswordInput);

        if (confirmPasswordInput.length === 0) {
            setPasswordErrMsg('');
            return;
        }

        if (confirmPasswordInput !== password.slice(0, confirmPasswordInput.length)) {
            setPasswordErrMsg('Passwords do not match');
        } else {
            setPasswordErrMsg('');
        }
    };

   
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFileName(file.name); // Update the selected file name
            setFileDetails(file);

            const allowedTypes = ['image/jpeg', 'image/png'];
            const maxSize = 200 * 1024; // 200 KB in bytes

            // Validate file type
            if (!allowedTypes.includes(file.type)) {
                alert('Only JPEG and PNG images are allowed!');
                event.target.value = null; // Clear the file input
                setFileDetails('');
                setSelectedFileName('');
                return; // Stop further processing
            }

            // Validate file size
            if (file.size > maxSize) {
                alert('File size should be less than 200 KB!');
                event.target.value = null; // Clear the file input
                setFileDetails('');
                setSelectedFileName('');
                return; // Stop further processing
            }
        }
    }
    const handleBrowseClick = (event) => {
        event.preventDefault();
        fileInputRef.current.click();  // Trigger click on hidden file input
    };
    const handleDragOver = (e) => {
        e.preventDefault();  // Prevent default behavior (prevent file from being opened)
    };

    // const handleDrop = (e) => {
    //     e.preventDefault();
    //     const file = e.dataTransfer.files[0];
    //     if (file) {
    //         handleFileChange({ target: { files: [file] } });
    //     }
    // };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.currentTarget.classList.add('drag-over');  // Add a class to show visual feedback
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.currentTarget.classList.remove('drag-over');  // Remove visual feedback when dragging leaves the element
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)

        // Validate phone number (only 10 digits allowed)
        if (!validateMobileNumber(phoneNumber)) {
            setMobileErrMsg("Invalid phone number");
            mobileRef.current.focus();
            setLoading(false)
            return;
        } else {
            setMobileErrMsg("");
        }

        // Validate password match
        if (password !== confirmPassword) {
            setPasswordErrMsg("Passwords do not match!");
            passwordRef.current.focus();
            setLoading(false)
            return;
        } else {
            setPasswordErrMsg("");
        }
        console.log("FORM SUBMITTED");

        // Gather all form data
        
        // Call handleRegister with the form data
        // handleRegister(formData);
        // console.log(formData);
        
        try {
            // alert(referralId)
            if (referralId != '') {
                const res = await axios.post(`${backend_path}/checkreferral`, { referralCode: referralId.trim() });
                console.log("ref code status", res.data.refcodeStatus);
                if (res.data.refcodeStatus == 1) {
                    setRefCodeMsg('')
                } else {
                    setRefCodeMsg('Invalid Code')
                    refcodeRef.current.focus()
                    setLoading(false)
                    return;
                }
            }
            const emailCheck = await axios.post(`${backend_path}/checkemail`, { email: email.trim() });
            console.log(emailCheck.data.emailStatus);
            if (emailCheck.data.emailStatus == 0) {
                setEmailMsg('')
            } else {
                setEmailMsg("Email already exists")
                emailRef.current.focus()
                setLoading(false)
                return;
            }

            const formData = {
                city: city,
                clg: collegeName,
                dept: departmentName,
                email: email,
                gender: gender,
                name: name,
                accom: accommodation,
                phone: phoneNumber,
                pswd: password,
                refcode: referralId,
                year: year,
                food: foodPreference,
                transid:transId,
                proof: receiptImage,
            };
            const fileReader=new FileReader();
            fileReader.onload =async function () {
                const fileBase64=fileReader.result;
                formData.proof=fileBase64
            // console.log("in filereader");

                const res=await axios.post(`${backend_path}/register`,formData);
                console.log(res.data.status);
                
                
                if(res.data.status=="Success"){
                    alert("Successfully registered, enjoy the events!")
                    navigate("/");
                }else{
                    alert("Oops Something went wrong, try again later")
                    navigate("/")
                }
                
            }
            fileReader.readAsDataURL(fileDetails)
            

        } catch (err) {
            console.error("Error during axios post :", err);
        }

        
        
        // handleRegister(formData);
        // console.log(formData);
        
        
        
        
    };

    return (
        <div className='register-sec'>
            <div className="register-container">
                {/* <div className="mobile-logo">
                    <img src={logo} alt="College Logo" />
                </div> */}
                <form className="register-form" onSubmit={handleSubmit}>
                    <div className="input-section">
                        <TextEffect className='register_head' val="REGISTRATION" repeatFlag={true} intervalTime={10000}/>
                        <p className='register-form-p reg_desc'>Enter the details carefully</p>
                        <div className="scrollable-inputs">
                            <div className="form-group">
                                <input
                                    className='form-input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-input"
                                    type="email"
                                    placeholder="E mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    ref={emailRef}
                                />
                            </div>
                            {EmailMsg && <p className='input_error_msg'>{EmailMsg}</p>}
                            <div className="form-group">
                                <input
                                    className="form-input"
                                    type="tel"
                                    placeholder="Phone number"
                                    ref={mobileRef}
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    required
                                />
                            </div>
                            {mobileErrMsg && <p className='input_error_msg reg_err_msg'>{mobileErrMsg}</p>}
                            <div className="form-group">
                                <select
                                    className='form-input-select '
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                    required
                                >
                                    <option value="" disabled>Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-input"
                                    type="text"
                                    placeholder="College name"
                                    value={collegeName}
                                    onChange={(e) => setCollegeName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-input"
                                    type="text"
                                    placeholder="City"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <select
                                    className='form-input-select '
                                    value={year}
                                    onChange={(e) => setYear(e.target.value)}
                                    required
                                >
                                    <option value="" disabled>Year</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-input"
                                    type="text"
                                    placeholder="Department Name"
                                    value={departmentName}
                                    onChange={(e) => setDepartmentName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-input"
                                    type="text"
                                    placeholder="Referral ID (optional)"
                                    value={referralId}
                                    onChange={(e) => setReferralId(e.target.value)}
                                    ref={refcodeRef}
                                />
                            </div>
                            {refCodeMsg && <p className='input_error_msg'>{refCodeMsg}</p>}
                            <p className='register-form-p reg_label_pswd'>SET PASSWORD</p>
                            <div className="form-group">

                                <input
                                    className="form-input"
                                    type="password"
                                    placeholder="Set Password"

                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    className="form-input"
                                    type="password"
                                    placeholder="Confirm Password"
                                    ref={passwordRef}
                                    value={confirmPassword}
                                    onChange={handlePassword}
                                    required
                                />
                            </div>
                            {passwordErrMsg && <p className='input_error_msg reg_err_msg'>{passwordErrMsg}</p>}

                            {/* Accommodation Selection */}
                            <p className='register-form-p reg_label_accom'>ACCOMMODATION</p>
                            <div className="form-group accommodation-selection">

                                <label className={`option-button ${accommodation === '1' ? 'active' : ''}`}>
                                    <input
                                        className="form-input form_accommodation_input"
                                        type="radio"
                                        value="1"
                                        checked={accommodation === '1'}
                                        onChange={(e) => setAccommodation(e.target.value)}
                                    />
                                    Yes
                                </label>
                                <label className={`option-button ${accommodation === '0' ? 'active' : ''}`}>
                                    <input
                                        className="form-input form_accommodation_input"
                                        type="radio"
                                        value="0"
                                        defaultChecked={true}
                                        checked={accommodation === '0'}
                                        onChange={(e) => setAccommodation(e.target.value)}
                                    />
                                    No
                                </label>
                            </div>

                            {/* Food Selection */}
                            {/* <p className='register-form-p'>FOOD</p>
                            <div className="form-group food-selection">

                                <label className={`option-button ${foodPreference === 'Veg' ? 'active' : ''}`}>
                                    <input
                                        type="radio"
                                        value="Veg"
                                        checked={foodPreference === 'Veg'}
                                        onChange={(e) => setFoodPreference(e.target.value)}
                                    />
                                    Veg
                                </label>
                                <label className={`option-button ${foodPreference === 'Non-Veg' ? 'active' : ''}`}>
                                    <input
                                        type="radio"
                                        value="Non-Veg"
                                        checked={foodPreference === 'Non-Veg'}
                                        onChange={(e) => setFoodPreference(e.target.value)}
                                    />
                                    Non-Veg
                                </label>
                            </div> */}
                            <p className='qr-display-p'>Scan the QR Code for Payment: <span className='price_text'>249Rs</span></p>
                            <div className='qr'>

                                <div className="qr-display">

                                    <img src={qrImage} alt="QR Code" className="qr-image" />
                                </div>

                                {/* Read-Only Phone Number */}
                                <div className="readonly-div">
                                    <p className='readonly1'>7598110935</p>
                                    <p className='readonly1'>9043740937</p>

                                </div>
                            </div>
                            <p className='register-form-p'>Upload Payment Receipt</p>
                            {/* Receipt Image Upload */}
                            <div
                                className="file-upload"
                                onDragOver={handleDragOver}
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                            >
                                
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    accept=".jpeg,.jpg,.png"
                                    onChange={handleFileChange}
                                    required
                                />
                                <div className="cloud-icon" onClick={handleBrowseClick}>
                                    <img src={cloud} className='cloud_img' alt="Upload Cloud" />
                                    <p>Drag & Drop or Click to Browse</p>
                                </div>
                                {selectedFileName && (
                                    <p >
                                        Selected file: {selectedFileName}
                                    </p>
                                )}
                                {/* {receiptImage && <p>Receipt Uploaded: {receiptImage.name}</p>} */}
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-input"
                                    type="text"
                                    placeholder="Transaction Id"
                                    value={transId}
                                    onChange={(e) => { setTransId(e.target.value) }}
                                    required
                                />
                            </div>
                        </div>
                        {!loading ?
                        (<div className='reg_btn'>
                        <button type="submit" className="submit-button">Register</button>
                        </div>)
                        :(<p className='form_loading_text'>Loading...</p>)}
                    </div>
                    <div className="logo-section">
                        <img src={logo} alt="College Logo" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Register;
