export const eventNames = {
    event1:"PAPER PRESENTATION",
    event2:"CONNEXION",
    event3:"BATTLE OF BIDS",
    event4:"CRYPTIC HAUNT",
    event5:"CSS ART",
    event6:"REBRAND RUMBLE",
    event7:"TECH ESCAPE",
    event8:"SQL BINGO",
    event9:"NINJA CODING",
    event10:"BUG BOUNTY BATTLE",
}