import React from 'react'
// import card from './photos/card.png'
import './Additionalcard.css'
import {TextEffect} from "../../Widget"
import { useNavigate } from 'react-router-dom'

import logo from '../../Assets/Additionalcard/logo.webp'
import card1 from '../../Assets/Additionalcard/card1.webp'
import card2 from '../../Assets/Additionalcard/card2.webp'
import card3 from '../../Assets/Additionalcard/card3.webp'
import card4 from '../../Assets/Additionalcard/card4.webp'
import card5 from '../../Assets/Additionalcard/card5.webp'
import card6 from '../../Assets/Additionalcard/card6.webp'
import card7 from '../../Assets/Additionalcard/card7.webp'
import card8 from '../../Assets/Additionalcard/card8.webp'
import card10 from '../../Assets/Additionalcard/card10.webp';
import card9 from '../../Assets/Additionalcard/card9.webp';
// import {data} from '../../Constants/additionalcard';


const Additionalcard =({data}) => {
  const images = {
    "card1.webp": card1,
    "card2.webp": card2,
    "card3.webp": card3,
    "card4.webp": card4,
    "card5.webp": card5,
    "card6.webp": card6,
    "card7.webp": card7,
    "card8.webp": card8,
    "card9.webp": card9,
    "card10.webp": card10
  };
  const navigate = useNavigate();
  return (
  
    <div className='addcard_holder'>
    <div className='addevent_head'>
    <TextEffect
              className="addcard-heading"
              val="Other Events"
              repeatFlag={true}
              intervalTime={10000}
            />
    </div>
    <div className='eventcard'>
      {Object.values(data).map((item) => (
        <div key={item.id} className='event-card-container' onClick={() => window.open(item.link, "_self")} >


          <h1 id="event-name">{item.title}</h1>
          <hr id="one"></hr>
          {/* <div> */}
            <div>
              <img src={images[item.image]} alt={item.title} id='card' />
              <div className='round'>
                <img id="logo" src={logo}></img>
              </div>
            {/* </div> */}
          </div>
        </div>))}
    </div>
    <div className='addevent_btn'>
    <button onClick={() => navigate("/events")} className="addcard_expbtn">Explore more</button>
    </div>
    </div>


  )
}

export default Additionalcard;