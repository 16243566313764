import HOD from '../Assets/images/hod.webp';
import Principal from '../Assets/images/principal.webp';
import Staffcoordinator from '../Assets/images/staffcoordinator.webp';
import Pink from '../Assets/images/team-card-bottom-pink.webp'; // Ensure correct extension

const Staffdata = [
    {
        teamImage: Principal,
        Teamcardbottom: Pink,
        role: "Principal/HOD of EEE",
        name: "Dr.K.Baskaran",
        fontcolor: "#FFC700"
    },
    {
        teamImage: HOD,
        Teamcardbottom: Pink,
        role: "HOD of CSE",
        name: "Dr.C.UmaRani",
        fontcolor: "#FFC700"
    },
    {
        teamImage: Staffcoordinator,
        Teamcardbottom: Pink,
        role: "Staff Co-ordinator",
        name: "S.Syed Suhaila",
        fontcolor: "#FFC700"
    },
];

export default Staffdata;