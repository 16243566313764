import React from "react";
import "./Contact.css"; 

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  return (
    <div className="contact-page">
      <div className="contact-background">
        <h1 className="contact-title">CONTACT</h1>

        <div className="contact-icons">
          <div className="insta-hold">
            <a
              href="https://www.instagram.com/acgcet_cse_asso/"
              target="_blank"
              rel="noopener noreferrer"
              className="contact-logo"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
          <div className="mail-hold">
            <a href="mailto:cseasso.acgcet@gmail.com" className="contact-logo">
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </div>
          <div className="linkedin-hold">
            <a
              href="https://www.linkedin.com/in/cse-association-96a731325/"
              target="_blank"
              rel="noopener noreferrer"
              className="contact-logo "
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>

        <div className="contact-box">
          <h2 className="contact_enq_head">General Inquiries</h2>
          <div className="contact-info">
            <div className="info-column1">
              <p>
                <a
                  href="http://www.linkedin.com/in/jawahar-rl-489aa426a"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Jawahar RL
                </a>
              </p>
              <p>
                <a href="mailto:jawaharrl2003@gmail.com">
                  jawaharrl2003@gmail.com
                </a>
              </p>
              <p>
                <a href="tel:9003821773">9003821773</a>
              </p>
            </div>
            <div className="info-column2">
              <p>
                <a
                  href="https://www.linkedin.com/in/kavya-k-59076b320?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Kavya K
                </a>
              </p>
              <p>
                <a href="mailto:Ckichan03@gmail.com">
                Ckichan03@gmail.com
                </a>
              </p>
              <p>
                <a href="tel:9489170204">9489170204</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
