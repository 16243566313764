import React, { useState } from 'react';
import './reset.css'; // Ensure this matches the actual file name
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import cseasso from '../../Assets/images/cseassologo.webp';
import { TextEffect } from '../../Widget';
import { useNavigate } from 'react-router-dom';
import { backend_path } from "../../Constants/backend";
import axios from 'axios';

const Reset = () => {
  const [formData, setFormData] = useState({
    Resetpassword: '',
    ConfirmResetpassword: '',
  });

  const [errors, setErrors] = useState({
    Resetpassword: '',
    ConfirmResetpassword: '',
  });

  const [showPassword, setShowPassword] = useState({
    Resetpassword: false,
    ConfirmResetpassword: false,
  });
  const [loading,setLoading]=useState(false)

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const validateForm = () => {
    let valid = true;
    const tempErrors = {};

    if (!formData.Resetpassword) {
      tempErrors.Resetpassword = 'Password is required';
      valid = false;
    }

    if (formData.Resetpassword !== formData.ConfirmResetpassword) {
      tempErrors.ConfirmResetpassword = 'Passwords do not match';
      valid = false;
    }

    setErrors(tempErrors);
    return valid;
  };

  const handleReset = async (e) => {
    e.preventDefault(); 
    console.log('Form data before validation:', formData);

    if (validateForm()) {
      setLoading(true)
      console.log('Form Submitted', formData.Resetpassword);
      const res=await axios.post(`${backend_path}/resetpassword`,{email:sessionStorage.email,pswd:formData.Resetpassword})
        if(res.data.status=="Success"){
          alert("Password updated Successfully")
          navigate("/login")
        }else{
          alert("Oops! Something went wrong, Try again later!")
          navigate("/login")
        }
    } else {
      console.log('Form has errors:', errors);
      // alert("Oops! Something went wrong, Try again later!")
       // Log the errors if validation fails
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <div className="reset-container">
      <div className="reset-form-container">
        <div className="reset-form-text">
          <TextEffect className="reset-h2" val="Reset" repeatFlag={true} intervalTime={5000}/>
          <TextEffect className="reset-h2" val="Password" repeatFlag={true} intervalTime={10000}/>
          <form onSubmit={handleReset} className="reset-form">
            <div className="form-group password-group">
              <input
                className="reset_form-input"
                type={showPassword.Resetpassword ? 'text' : 'password'}
                id="Resetpassword"
                name="Resetpassword"
                value={formData.Resetpassword}
                onChange={handleChange}
                placeholder="Reset Password"
                aria-describedby="reset-password-error" // Accessibility improvement
              />
              <span className="login_password-icon" onClick={() => togglePasswordVisibility('Resetpassword')}>
                <FontAwesomeIcon icon={showPassword.Resetpassword ? faEyeSlash : faEye} />
              </span>
              </div>
              {errors.Resetpassword && (
                <span className="input_error_msg" id="reset-password-error">{errors.Resetpassword}</span>
              )}

            <div className="form-group password-group">
              <input
                className="reset_form-input"
                type={showPassword.ConfirmResetpassword ? 'text' : 'password'}
                id="ConfirmResetpassword"
                name="ConfirmResetpassword"
                value={formData.ConfirmResetpassword}
                onChange={handleChange}
                placeholder="Confirm Password"
                aria-describedby="confirm-reset-password-error" // Accessibility improvement
              />
              <span className="login_password-icon" onClick={() => togglePasswordVisibility('ConfirmResetpassword')}>
                <FontAwesomeIcon icon={showPassword.ConfirmResetpassword ? faEyeSlash : faEye} />
              </span>
            </div>
            {errors.ConfirmResetpassword && (
                <span className="input_error_msg" id="confirm-reset-password-error">{errors.ConfirmResetpassword}</span>
              )}

            <div className='reset_butt_holder'>
            {!loading ?
            (<button type="submit" className="reset-button">
              Submit
            </button>)
            :(<p className='form_loading_text'>Loading...</p>)}
            </div>
          </form>
        </div>

        <div className="reset-form-img-cont">
          <img className="reset-form-img" src={cseasso} alt="CSE Association" />
        </div>
      </div>
    </div>
  );
};

export default Reset; // Updated component name
