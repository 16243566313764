// import './App.css';
import { Routes, Route } from "react-router-dom";
import axios from "axios"
import {
  Home,
  Register,
  Contact,
  Sqlbingo,
  Login,
  Reset,
  ForgotPassword,
  Event,
  Connexion,
  Battle,
  Cryptic,
  Ninja,
  Rebrand,
  Techescape,
  PaperPresent,
  Cssart,
  Debug,
  Teams,
  Dashboard,
} from "./Pages";
import { Navbar, Footer } from "./Components";
import ReqModal from './Components/ReqModal/ReqModal';
import OptionalModal from './Components/OptionalModal/OptionalModal';

function App() {
  axios.defaults.withCredentials = true;
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path="/register" element={<Register />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/events" element={<Event />} />
        <Route path="/dashboard" element={<Dashboard />} />
        
        <Route path="/battle" element={<Battle />} />
        <Route path="/connexion" element={<Connexion />} />
        <Route path="/cryptic" element={<Cryptic />} />
        <Route path="/ninja" element={<Ninja />} />
        <Route path="/rebrand" element={<Rebrand />} />
        <Route path="/techescape" element={<Techescape />} />
        <Route path="/paperpresent" element={<PaperPresent />} />
        <Route path="/sqlbingo" element={<Sqlbingo />} />
        <Route path="/cssart" element={<Cssart />} />
        <Route path="/debug" element={<Debug />} />
        <Route path="/team" element={<Teams />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
